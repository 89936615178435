//我的订单
export const orderTitle={
    ZH:{
        orderTitle:'我的订单'
    },
    EN:{
        orderTitle:'My Bookings'
    }
}
//订单列表
export const order={
    ZH: {  
        // orderAll:'全部',
        orderParcel:'包裹',
        // orderAir:'货运',
        // orderFlights:'机票',
    },
    EN: {  
        // orderAll:'All',
        orderParcel:'Parcel',
        // orderAir: "Cargo",
        // orderFlights:'Flights',
    }
}
//全部订单
export const orderall={
    ZH:{
        all:'所有订单',
        old:'已付款',
        wait:'待付款',
        cancel:'已取消',
    }, 
    EN:{
        all:'All orders',
        old:'Paid',
        wait:'Pending payment',
        cancel:'Canceled',
    },
}
//包裹订单
export const parcelList={
    ZH:{
        orderDate:'订单日期',
        orderNumber:'订单号',
        postCode:'邮编',
        price:'价格',
        tracking:'跟踪',
        searchDate:'日期搜索',
        search:'搜索',
        orderView:'查看所有订单',
        Statement:'下载声明',
        invoice:'下载发票',
        parcelOrder:'包裹订单',
        cargoOrder:'货运订单',
        AirOrder:'机票订单',
        delivered:'交付',
        newTracking:'最新跟踪',
        placOrder:'下单成功',
        Collect:'揽收',
        Collected:'仓库',
        transit:'航空',
        Depot:"海关",
        Delivery:'派送',
        Delivered:'交付',
        ReturnAddress:'派送地址',
        EditAddress:'编辑地址',
        DeliveryAddress:'取件地址',
        ReturnCollection:'安排退件',
        payAddition:'支付额外的费用',
        BookingDetails:'订单详情',
        AdditionalExtras:'额外附加费用',
        DownloadInvoice:'下载发票',
        DownloadLabels:'下载标签和文档',
        BuyAgain:'再次购买',
        imported:'导入的项目',
        Dispatched:'下单时间',
    },
    EN:{
        orderDate:'Order date',
        orderNumber:'Order number',
        postCode:'PostCode',
        price:'Price',
        tracking:'Tracking',
        searchDate:'Search by date',
        search:'General Search',
        orderView:'View all orders',
        Statement:'Download Statement',
        invoice:'Download invoices',
        parcelOrder:'Parcel Order',
        cargoOrder:'Air Orders',
        AirOrder:'Flight Orders',
        delivered:'Delivered',
        newTracking:'Latest Tracking',
        placOrder:'place an order',
        Collect:'Collect ',
        Collected:'confirmation',
        transit:'In transit',
        Depot:"At Depot",
        Delivery:'Out for Delivery',
        Delivered:'Delivered',
        ReturnAddress:'Delivery address',
        EditAddress:'Edit Address',
        DeliveryAddress:'Delivery Address',
        ReturnCollection:'Arrange Return Collection',
        payAddition:'Pay additional fees',
        BookingDetails:'Booking Details',
        AdditionalExtras:'Additional Extras',
        DownloadInvoice:'Download Invoice',
        DownloadLabels:'Download Labels & Docs',
        BuyAgain:'Buy Again',
        imported:'imported Item',
        Dispatched:'order time',
    },
}
//财务管理
export const walletTitle={
    ZH:{
        walletTitle:'财务管理'
    },
    EN:{
        walletTitle:'Financial management'
    }
}
export const wallet={
    ZH:{
        payAccount:'充值账户',
        payment:'立即充值',
        // payRecord:'申请记录',
        payInvoice:'发票管理'
    },
    EN:{
        payAccount:'Your prepay history',
        payment:'Immediately pay',
        // payRecord:'Application record',
        payInvoice:'Invoice management'
    },
}
//充值账户
export const payAccount={
    ZH:{
        all:'全部',
        income:"收入",
        expend:'支出',
        pay:'充值',
        search:'输入关键词搜索',
        yue:'余额',
        dateTime:'日期时间',
        date:'日期',
        time:'时间',
        businessNumber:'交易流水号',
        relevance:'关联单号',
        road:'交易渠道',
        remark:'交易备注',
        price:'交易金额',
        accountYue:'账户余额',
        filter:'高级筛选',
        dateRange:'请选择日期范围',
        start:'开始日期',
        end:'结束日期',
        reset:'重置',
        submit:'筛选',
        to:'至',
        choose:'请选择',
        Transaction:'交易来源',
        TransactionType:'交易类型',
        upload:'点击加载更多',
        nodata:'没有数据了',
        inputNumber:'请输入数字',
        trip:'（预付款必须至少为20.00英镑）',
    },
    EN:{
        all:'All',
        income:"Income",
        expend:'Expend',
        pay:'Add Funds',
        search:'Enter keyword search',
        yue:'Prepay Balance',
        dateTime:'Date Time',
        date:'Date',
        time:'Time',
        businessNumber:'Transaction Type',
        relevance:'Associated Doc No',
        road:'Trading channels',
        remark:'Transaction Notes',
        price:'Transaction amount',
        accountYue:'Balance',
        filter:'Advanced filtering',
        dateRange:'Please select a date range',
        start:'Start Date',
        end:'End Date',
        reset:'Resetting',
        submit:'Submit',
        to:'to',
        choose:'Choose',
        Transaction:'Transaction source',
        TransactionType:'Transaction type',
        upload:'Click to load more',
        nodata:'No data',
        inputNumber:'please enter a number',
        trip:'(Prepayments must be for a minimum of £20.00)',
    },
}
//立即充值
export const paynow={
    ZH:{
        Recharge:'充值',
        other:'其他金额',
        clickPay:'点击支付',
    },
    EN:{
        Recharge:'Recharge',
        other:'Other amounts',
        clickPay:'Click to Pay',
    },
}
//申请记录
export const applyLog={
    ZH:{
        applyLog:'申请记录',
        dateTime:'日期时间',
        applyStatus:'申请状态',
        status:'申请状态',
        source:'交易来源',
        type:'申请类型',
        price:'金额',
        number:'申请号',
        operate:'操作',
        search:'输入关键词搜索',
        filter:'高级筛选',
        dateRange:'请选择日期范围',
        start:'开始日期',
        end:'结束日期',
        reset:'重置',
        submit:'筛选',
        to:'至',
    },
    EN:{
        applyLog:'Application Record',
        dateTime:'Date Time',
        applyStatus:'Application Status',
        status:'Application Status',
        source:'Transaction source',
        type:'Application Type',
        price:'amount of money',
        number:'Application No',
        operate:'Operate',
        search:'Enter keyword search',
        filter:'Advanced filtering',
        dateRange:'Please select a date range',
        start:'Start Date',
        end:'End Date',
        reset:'Resetting',
        submit:'Submit',
        to:'to',
    },
}
//发票管理
export const invoice={
    ZH:{
        manage:'发票管理',
        all:'全部',
        income:"收入",
        expend:'支出',
        search:'输入关键词搜索',
        filter:'高级筛选',
        dateRange:'请选择日期范围',
        start:'开始日期',
        end:'结束日期',
        remark:'备注',
        reset:'重置',
        submit:'筛选',
        to:'至',
        choose:'请选择',
        number:'发票号',
        price:"发票金额",
        type:'类型',
        way:'付款方式',
        scope:'日期范围',
        date:'发票日期',
        operate:'操作',
    },
    EN:{
        manage:'Control over invoices',
        all:'All',
        income:"Income",
        expend:'Expend',
        search:'Enter keyword search',
        filter:'Advanced filtering',
        dateRange:'Please select a date range',
        start:'Start Date',
        end:'End Date',
        remark:'Remark',
        reset:'Resetting',
        submit:'Submit',
        to:'to',
        choose:'Choose',
        number:'Invoice No',
        price:"Invoice amount",
        type:'Type',
        way:'Payment method',
        scope:'Order Date Range',
        date:'Invoice Date',
        operate:'Operate',
    },
}
//账户管理
export const accountTitle={
    ZH:{
        accountTitle:'账户管理'
    },
    EN:{
        accountTitle:'Account management'
    }
}
export const accountManage={
    ZH:{
        accountSet:'账户设置',
        safetySet:'安全设置',
        accountReal:'实名认证',
        accountRealList:'认证列表',
        accountLink:'关联账户',
        addressSet:'地址管理',
        // packingList:'装箱单管理'
    },
    EN:{
        accountSet:'Account settings',
        safetySet:'security settings',
        accountReal:'Real name authentication',
        accountRealList:'Certification List',
        accountLink:'Linked Accounts',
        addressSet:'Address management',
        // packingList:'Packing list management'
    }
}
//登录注册
export const login={
    ZH:{
        login:'登录 / 注册',
        loginaccount:'登录',
        useVertify:'使用验证码登录',
        registerAccount:'注册',
        register:'立即注册',
        loginNow:'登录/注册',
        continue:'下一步',
        enterYourEmail:'请输入您用于注册的电子邮件地址。我们将向此电子邮件地址发送一个验证码',
        containUser:'已有账户',
        noAccount:'没有账户',
        emailRegister:'邮箱注册',
        phoneRegister:'手机注册',
        emailLogin:'邮箱登录',
        emailPlaceholder:'请输入电子邮件地址',
        phoneLogin:'手机登录',
        getCode:'获取验证码',
        moreSign:'更多登陆方法',
        enterPassword:'输入密码',
        enterYourPassword:'请输入您的密码',
        readAgree:'通过登录或注册，即视为您已同意',
        serve:'条款和条件',
        and:'和',
        clause:'隐私声明',
        WeChat:'微信',
        forgotPassword:'忘记密码',
        resetPassword:'重置密码',
        setPassword:'请设置对应帐户的密码',
        passwordRules:'密码必须为8-20个字符，并且包括字母、数字和符号。',
        forgotEmail:'输入邮箱',
        phoneNumber:'请输入您的手机',
        enterVertify:'请输入验证码',
        introduce:'请输入您注册时使用的电子邮件地址。我们会把验证码发到这个邮箱',
        accountPassword:'请输入对应帐户的密码',
        drop:'向右拖动滑块',
        send:'发送',
        vertifyEmail:'验证电子邮件',
        vertifyContent:'为了确保您的账户安全，我们刚刚向',
        vertifyContent2:'发送了验证码。请输入密码以验证您的电子邮箱地址',
        vertifyCode:'验证码',
        resend:'重新发送',
        vertify:'验证',
        charcter:'8-20个字符',
        confirm:'确定',
        newSetPassword:'设置密码',
        tip:'请输入有效的电子邮件',
        rememberPassword:'记住密码',
    },
    EN:{
        login:'Sign In / Register',
        loginaccount:'Sign In',
        useVertify:'Login using verification code',
        registerAccount:'Register',
        register:'Register',
        loginNow:'Login/Registration',
        continue:'Continue',
        enterYourEmail:"Please enter the email address you used to register We'll send a vertification code to this email address.",
        containUser:'Existing account',
        noAccount:'No account temporarily',
        emailRegister:'Email',
        phoneRegister:'Mobile',
        emailLogin:'Mailbox login',
        emailPlaceholder:'Please enter an email address',
        phoneLogin:'Mobile login',
        enterPassword:'Enter Password',
        enterYourPassword:'Please enter your password',
        getCode:'Get Code',
        moreSign:'More Sign In Methods',
        readAgree:'By signing in or registering, you are deemed to have agreed to the',
        serve:'Terms and Conditions ',
        and:' and ',
        clause:'Privacy Statement',
        WeChat:'WeChat',
        forgotPassword:'Forgot password',
        resetPassword:'Reset Password',
        setPassword:'Please set the password of the account corresponding to',
        passwordRules:'The password must be 8–20 characters and include letters, numbers, and symbols.',
        forgotEmail:'Enter Your Email',
        phoneNumber:'Please enter your phone number',
        enterVertify:'Please enter the verification code',
        introduce:"Please enter the email address you used to register. We'll send a verification code to this email address.",
        accountPassword:'Please enter the password of the account corresponding to',
        drop:'Drag the slider to the right',
        send:'Send',
        vertifyEmail:'Verify Your Email',
        vertifyContent:'To secure your account, we have just sent a verification code to',
        vertifyContent2:'Please enter the code to verify your email address.',
        vertifyCode:'Verification Code',
        resend:'Resend',
        vertify:'Verify',
        charcter:'8-20 Charcters',
        confirm:'Confirm',
        newSetPassword:'Set Password',
        tip:'Please enter a valid email',
        rememberPassword:'Remember password',
    }
}
//账户设置
export const accountSet={
    ZH:{
        accountSet:'账户设置',
        personInfo:'个人信息',
        Myaccount:'我的账号',
        editName:'编辑姓名',
        registermail:'电子邮箱',
        registerphone:'注册手机',
        registerDate:'注册日期',
        accountCategory:'账户类别',
        myName:'姓名',
        surname:'名',
        foreName:'姓',
        addressme:'性别',
        otherphone:'其他电话',
        wx:'微信号',
        qq:'QQ号',
        delivery:'编辑默认取件地址',
        person:'个人信息',
        change:'更换头像',
        username:'用户名',
        account:'我的账号',
        email:'邮箱',
        contacts:'联系人:',
        phone:'联系电话:',
        company:'公司:',
        address1:'街道地址1:',
        address2:'街道地址2:',
        city:'城市:',
        group:'群:',
        postcode:'邮编:',
        country:'国家地区:',
        reset:'重置',
        submit:'提交',
        saveAddress:'保存的地址',
        addAddress:'添加地址',
        editinvoice:'编辑发票地址',
        invoice:'发票类型:',
        name:'人名/公司名:',
        state:'群/省/州:',
        commit:'提交更改',
        sir:'先生',
        lady:'女士',
        maintainsecrecy:'保密',
        entername:'请输入姓名',
        enterphone:'输入其他电话',
        enterwx:'输入微信号',
        enterqq:'输入QQ号',
        defaultAddress:'默认取件地址',
        nodata:'无数据',
        defaultinvoice:'默认发票地址',
        house_number:'房屋编号',
        locality:'省',
        town:'市',
        county:'区/县',
        instructions:'介绍',
    },
    EN:{
        accountSet:'Account settings',
        personInfo:'Personal information',
        Myaccount:'My Account',
        editName:'Edit Name',
        registermail:'E-mail',
        registerphone:'Mobile phone',
        registerDate:'Registration date',
        accountCategory:'Account category',
        myName:'Name',
        surname:'FirstName',
        foreName:'LastName',
        addressme:'How to address me',
        otherphone:'Other calls',
        wx:'Wechat number',
        qq:'QQ No',
        delivery:'Edit default shipping address',
        person:'Personal information',
        change:'Change',
        username:'User name',
        account:'My account',
        email:'email',
        contacts:'Contacts:',
        phone:'Phone:',
        company:'Company came:',
        address1:'Street address 1:',
        address2:'Street address 2:',
        city:'City:',
        group:'Group:',
        postcode:'Postcode:',
        country:'Country:',
        reset:'Reset',
        submit:'Filter',
        saveAddress:'Saved Address',
        addAddress:'Add Address',
        editinvoice:'Edit invoice address',
        invoice:'Invoice type',
        name:'Name/company',
        state:'Group/Province/State',
        commit:'Commit changes',
        sir:'sir',
        lady:'lady',
        maintainsecrecy:'Maintain secrecy',
        entername:'Please enter your name',
        enterphone:'Enter another phone number',
        enterwx:'Input micro-signal',
        enterqq:'Enter QQ number',
        defaultAddress:'Default delivery address',
        nodata:'No data',
        defaultinvoice:'Default invoice address',
        house_number:'house_number',
        locality:'locality',
        town:'town',
        county:'county/县',
        instructions:'instructions',
    }
}
//安全设置
export const safe={
    ZH:{
        country:['中国','英国'],
        password:'密码',
        safeSet:'安全设置',
        vertifyEmail:'验证邮箱',
        vertify:'验证',
        username:'用户名',
        userintroduce1:'您当前的用户名为',
        userintroduce2:'，可以用来登陆您的账号',
        loginpassword:'登陆密码',
        intensity:'当前密码强度：',
        level1:'强',
        level2:'中',
        level3:'弱',
        loginintroduce:'建议您定期更换密码，设置安全性高的密码可以使账号更安全',
        changepassword:'更改密码',
        email:'电子邮箱绑定',
        emailintroduce:'绑定电子邮箱可用于登陆账号，重置密码',
        binding:'立即绑定',
        unbind2:'解除绑定',
        Unbound:'未绑定',
        verified:'未验证',
        phoneBind:'手机号绑定',
        phonecontent:'绑定手机可用于登陆账号，重置密码',
        wxlogin:'微信登录',
        wxcontent:'绑定后可通过微信扫码来登陆账号',
        oldPassword:'旧密码',
        newPassword:'新密码',
        confirmPassword:'确认密码',
        bindEmail:'绑定邮箱',
        submit:'提交',
        mailbox:'邮箱',
        no:'无',
        bind:'绑定',
    },
    EN:{
        country:['CHINA','UK'],
        password:'Password',
        vertifyEmail:'Verify Mailbox',
        vertify:'Vertify',
        safeSet:'Security setting',
        username:'User name',
        userintroduce1:'Your current user name is',
        userintroduce2:'，Can be used to log in to your account',
        loginpassword:'Login password',
        intensity:'Current password strength：',
        level1:'strong',
        level2:'centre',
        level3:'weak',
        loginintroduce:'It is recommended that you change your password regularly. Setting a password with high security can make your account more secure',
        changepassword:'Change password',
        email:'E-mail binding',
        emailintroduce:'Binding email can be used to login account and reset password',
        binding:'Bind Now',
        unbind2:'Unbind',
        Unbound:'Unbound',
        verified:'Not verified',
        phoneBind:'Mobile number binding',
        phonecontent:'Binding mobile phone can be used to login account and reset password',
        wxlogin:'WeChat login',
        wxcontent:'After binding, you can log in through WeChat scanning',
        oldPassword:'Old Password',
        newPassword:'New Password',
        confirmPassword:'Confirm Password',
        bindEmail:'Bind email',
        submit:'Submit',
        mailbox:'Email',
        no:'No',
        bind:'Bind',
    },
}
//实名认证
export const realName={
    ZH:{
        name:'姓名',
        idCard:'身份证号码',
        phoneNumber:'手机号码',
        idCardLifespan:'身份证有效期',
        title:'实名认证',
        Management:'中国进境物品申报委托证件管理系统',
        uploadIntroduce :'请分别上传身份证正面和背面照片',
        idCardFront:'上传身份证正面',
        idCardOpposite:'上传身份证反面',
        photos:'身份证照片要求',
        attention1:'请上传有效期内二代身份证的彩色照片，上传后姓名和身份证号码会自动识别',
        attention2:'所有上传的身份证信息都会加密传输和储存，并且仅用于海关清关使用',
        submit:'提交',
    },
    EN:{
        name:'Name',
        idCard:'ID No',
        phoneNumber:'Phone Number',
        idCardLifespan:'Validity period of ID card',
        title:'Real name authentication',
        Management:'China Entry Item Declaration Entrusted Document Management System',
        uploadIntroduce :'Please upload photos of the front and back of your ID card separately',
        idCardFront:'Upload the front of the ID card',
        idCardOpposite:'Upload the reverse side of the ID card',
        photos:'Requirements for ID card photos',
        attention1:'Please upload the color photo of the second-generation ID card within the validity period. After uploading, the name and ID card number will be automatically identified',
        attention2:'All uploaded ID information will be encrypted for transmission and storage, and will only be used for customs clearance purposes',
        submit:'Submit',
    },
}
//实名认证列表
export const realList={
    ZH:{
        title:'实名认证列表',
        name:'姓名',
        sex:'性别',
        idNumber:'身份证号码',
        address:'地址',
        phone:'手机号',
        edit:'编辑',
        delete:'删除',
        attestation:'认证信息',
        submit:'提交',
        cardDetails:'证件详情',
    },
    EN:{
        title:'Real name authentication list',
        name:'Name',
        sex:'gender',
        idNumber:'ID No',
        address:'Address',
        phone:'Phone Number',
        edit:'Edit',
        attestation:'Certification information',
        submit:'Submit',
        delete:'Delete',
        cardDetails:'Document details',
    },
}
//关联账户
export const link={
    ZH:{
        titleIntroduce:"通过链接第三方网站，您可以直接使用您的第三方账户信息登录",
        wx:'微信',
        phone:'手机',
        email:"邮箱",
        bind:'绑定',
        unbind:'解除绑定',
        yesBind:'已绑定',
    },
    EN:{
        titleIntroduce:"By linking a third party site, you'll be able to sign in directly using your third party account information.",
        wx:'Wechat',
        phone:'Phone',
        email:"Email",
        bind:'Link',
        unbind:'Unbind',
        yesBind:'Bound',
    },
}
//地址管理
export const address={
    ZH:{
        addressbook:'地址簿',
        sender:'取件人地址',
        addresssee:'派送人地址',
        invoiceAddress:'发票地址',
        name:'派送人',
        name2:'取件人',
        name3:'联系人',
        phone:'联系电话',
        addressdetails:'详细地址',
        locality:'省',
        town:'市',
        county:'区',
        street:'街道',
        house_number:'房屋编号',
        postcode:'邮编',
        country:'国家地区',
        otheraddress:'地址别名',
        operate:'操作',
        input:'输入关键词搜索',
    },
    EN:{
        addressbook:'Address Book',
        sender:'Return address',
        addresssee:'Recipient address',
        name:'Recipient name',
        invoiceAddress:'Invoiced address',
        name2:'sender',
        name3:'contacts',
        phone:'Phone',
        addressdetails:'Address',
        locality:'province',
        town:'city',
        county:'District',
        street:'street',
        house_number:'House number',
        postcode:'Zip code',
        country:'Country/region',
        otheraddress:'Address alias',
        operate:'operate',
        input:'Please enter keyword search'
    }
}
//装箱单管理
export const packingList={
    ZH:{
        title:'装箱单管理',
        all:'全部',
        name:'装箱单名称',
        num:'物品数量',
        weight:'包裹重量',
        size:'包裹尺寸',
        server:'物流服务',
        data:'创建日期',
        operate:'操作',
        input:'请输入关键字搜索',
        nodata:'暂无数据',
        filter:'高级筛选',
        dateRange:'创建日期范围',
        start:'开始日期',
        end:'结束日期',
        logistics:'物流服务',
        reset:'重置',
        submit:'筛选',
        search:'关键字搜索',
        to:'至',
        checked:'全选',
        delete:'删除',
        define:'确定删除吗'
    },
    EN:{
        title:'Packing list management',
        all:'All',
        name:'Packing list name',
        num:'Item quantity',
        weight:'Package weight',
        size:'Package size',
        server:'Logistics services',
        data:'Created on',
        operate:'operate',
        input:'Please enter keyword search',
        nodata:'No data',
        filter:'Advanced filtering',
        dateRange:'Date range',
        start:'Start Date',
        end:'End Date',
        logistics:'Logistics services',
        reset:'Resetting',
        submit:'Submit',
        search:'Search',
        to:'to',
        checked:'Select All',
        delete:'Delete',
        define:'Are you sure to delete'
    }
}