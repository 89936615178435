export const parcelStatus={
    ZH:{
        parcelStatus:'包裹状态'
    },
    EN:{
        parcelStatus:'Parcel Status'
    }
}
export const parcelSetup={
    ZH:{
        parcelDetails:'包裹详情',
        addressDetails:'地址详情',
        Customs:'海关',
        Basket:'购物车',
        Payment:'支付',
    },
    EN:{
        parcelDetails:'Parcel Details',
        addressDetails:'Address Details',
        Customs:'Customs',
        Basket:'Basket',
        Payment:'Payment',
    },
}
//包裹的侧边介绍
export const sideBarRight={
    ZH:{
        introduce:`我们将从您家或
        工作地址并提供已签名的交付
        到派送人的门。非常适合紧急情况
        和时间敏感地块`,
        Proof:'交货证明',
        Online:'在线跟踪',
        contents:'标准包括20种内容，如有需要可选择扩展',
        Affix:'预订完成后，在包裹外部贴上标签',
        Transit:'报价的运输时间从我们的一名司机收包裹后的第二天开始，不包括非工作日。',
        Taxes:'一旦您的包裹到达目的地国家，派送人可能会在发货前支付税款和关税。被称为“交货地点（DAP）国际贸易术语解释通则”，即接收方负责所有进口关税和税款（如适用）。',
        must:'您必须说明包裹内所有货物的真实和准确价值才能清关。否则，您的包裹可能会被延误、罚款或处罚。',
        Collection:'08:00至18:00之间收集',
        Restrictions:'限制规定',
        Breakdown:'明细',
        Worldwide:'全球航空站到门口',
        sendup:`发送最多25公斤
        最大尺寸为100cm x 80cm x 80厘米
        装运前检查限制和禁止物品清单
        无黑色塑料外包装-自动包裹分拣系统无法处理包裹在黑色外包装中的包裹
        未能申报包裹的正确重量和尺寸可能意味着您会产生额外费用/包裹将以额外费用退还给您。`,
        BasePrice:'运价',
        coverPrice:'保险价格',
        VAT:'增值税',
        total:'总价',
    },
    EN:{
        introduce:`We’ll collect your parcel from your home or 
        work address and provide signed for delivery 
        to your recipient’s door. Perfect for urgent 
        and time sensitive parcels`,
        Proof:'Proof of delivery',
        Online:'Online tracking',
        contents:'20 contents cover included as standard with the option to extend if needed',
        Affix:'Affix label(s) to the outside of parcel once your booking is completed',
        Transit:'Transit times quoted start the day after your parcel is collected by one of our drivers and excludes non-working days.',
        Taxes:'Taxes and duties may be payable by the recipient before the shipment(s) are released for delivery, once your parcel is at its destination country. Known as ‘’Delivered At Place (DAP) incoterms’’ this is where the recipient is responsible for all import duties and taxes - if applicable.',
        must:'You must state the true and accurate value of all goods inside your parcel(s) to clear customs. Failure to do so means your parcel(s) may incur delays, fines or penalties.',
        Collection:'Collection between 08:00 - 18:00',
        Restrictions:'Restrictions',
        Breakdown:'Breakdown',
        Worldwide:'Worldwide Air - Depot to Door',
        sendup:`Send up to 25kg
        Maximum dimensions of 100cm x 80cm x 80cm
        Check the restricted and prohibited items list before shipping
        No black plastic outer wrapping - the automated parcel sortation systems can not process parcels encased in black outer wrapping
        Failure to declare the correct weight and dimensions of your parcel could mean you incur extra charges / your parcel will be returned back to you at an additional fee.`,
        BasePrice:'Base Price',
        coverPrice:'Cover',
        VAT:'VAT',
        total:'Total',
    },
}
//包裹详情(第一步)
export const parcelDetails={
    ZH:{
        title:'标题',
        Forename:'名',
        Surname:'姓',
        Email:'邮箱',
        oneTitle:'包裹详情',
        contents:'包裹内件信息',
        value:'包裹价值',
        length:'长度',
        width:'宽度',
        height:'高度',
        weight:'重量',
        duplicate:'复制包裹',
        another:'增加包裹',
        remove:'删除包裹',
        mutilple :'发送多个包裹',
        can:'你可以点击',
        here:'这里',
        bulkOrders:'下批量订单',
        protection:'包裹保险',
        mutilpleContent:'在这里上传您的csv，我们的csv上传工具很灵活，可以通过将您的列映射到我们需要的信息来上传任何电子表格文件。',
        already:'您的包裹已经受到保护，最高可达',
        readAgress:'我已阅读并同意禁止和限制项目列表',
        protectionDetails:'您的包裹未受保护，填写信息后可选择是否保护',
        wouldLike:'是的，我想保护我的',
        against:'包裹免受损失或损坏,需额外支付',
        willing:'不，我愿意冒我的风险',
        worried:'包裹我不担心潜在的损失或损坏',
        higher:'您的包裹价值高于',
        includedParcel:'包括包裹保护。是否要添加完全保护',
        fully:'添加保险',
        noprotect:'不添加保险',
        next:'下一步',
        myDetail:'我的详情',
        login:'登录',
        downLoadTemplate:'下载模板',
    },
    EN:{
        title:'Title',
        Forename:'Forename',
        Surname:'Surname',
        Email:'Email',
        oneTitle:'Parcel Details',
        contents:'Contents',
        value:'Value',
        length:'Length',
        width:'Width',
        height:'Height',
        weight:'Weight',
        duplicate:'Duplicate Parcel',
        another:'Add Another Parcel',
        remove:'Remove Parcel',
        mutilple :'Send mutilple parcel',
        can:'You can click',
        here:'here',
        bulkOrders:'to place bulk orders',
        protection:'Parcel Protection',
        mutilpleContent:'Upload your csv here Our CSV upload tool is flexible and allows you to upload any spreadsheet file by mapping your columns to the information that we require.',
        already:'Parcel Protection',
        readAgress:'I have read and agree to the prohibited & restricted items list',
        protectionDetails:'Your package is not protected. After filling in the information, you can choose whether to protect it or not',
        wouldLike:'Yes, I would like to protect my',
        against:'parcel against loss or damage for',
        willing:"No, I'm willing to risk my",
        worried:"parcel. I'm not worried about potential loss or damage'",
        higher:'Your parcel value is higher than the',
        includedParcel:'included Parcel Protection. Would you like to add full protection',
        fully:'Yes, fully protect for',
        noprotect:"No, don't protect my parcel",
        next:'Next',
        myDetail:'My Details',
        login:'Login',
        downLoadTemplate:'Download Template',
    },
}
//包裹地址详情（第二步）
export const addressDetails={
    ZH:{
        Receiving:'请选择派送地址',
        invoiceAddress:'请选择发票地址',
        name:'姓名',
        HouseNumber:'街道门牌',
        street:'街道',//
        town:'市',//
        email:'邮箱',
        postCode:'邮编',
        addressSearch:'邮编或地址智能搜索',
        phoneNumber:'电话号码',
        operate:'操作',
        useAddress:'使用地址',
        sending:'请选择取件地址',
        Collection:'派送地址',
        address:'地址',
        savedAddress:'保存地址',
        edit:'编辑',
        Instructions:'说明',
        noData:'无数据',
        search:'搜索',
        contactName:'联系人姓名',
        Company:'公司',
        Locality:'区',//
        County:'省',//
        Country:'国家',
        enterManualy:'地址手动填写',
        useSavedAddress:'使用保存的地址',
        newAdressSearch:'地址智能搜索',
        deliveryDetails:'取件地址',
        Organisation:'组织',
        door:'门、楼层和建筑物或位置',
        addressLine1:'地址行1',
        addressLine2:'地址行2',
        addressLine3:'地址行3',
        Province:'详细地址',
        Back:'回退',
        next:'下一步',
        confirmParcelTitle:'我们需要更多的信息',
        saveCollection:'保存派送地址',
        saveDeliveryDetails:'保存取件地址',
        noServiceTip:'遗憾的是，我们无法根据您提供给我们的详细信息为您提供服务。',
        NewQuote:'获取新的报价',
        returnHomePage:'返回主页',
        collectionDate:'收件地址',
        collectionParcel1:'您包裹的收货日期为',
        collectionParcel2:'如果您想更改此日期',
        collectionParcel3:'请点击这里',
        selectedDate:'选定日期',
        workingDays:'请注意，交货时间为工作日。',
        serviceParcel:'这项服务可以在周一至周五提供。在估计包裹的交付量时，请记住这一点。',
        autoIdentify:'自动识别并填入地址',
        textareaPlac:'可以直接粘贴收件人的完整地址和联系方式，系统为您自动识别并填入',
    },
    EN:{
        Receiving:'Please select a shipping address',
        invoiceAddress:'Please select invoice address',
        name:'Name',
        HouseNumber:'House Number',
        street:'Street',
        town:'Town',
        email:'Email',
        postCode:'Postcode',
        addressSearch:'Address Intelligent Search',
        phoneNumber:'Phone Number',
        operate:'operate',
        useAddress:'Use Address',
        sending:'Please select a shipping address',
        Collection:'Shipping address',
        address:'Address',
        savedAddress:'Saved Address',
        edit:'Edit',
        Instructions:'Instructions',
        noData:'No Data',
        search:'Search',
        contactName:'Contact Name',
        Company:'Company',
        Locality:'Locality',
        County:'County',
        Country:'Country',
        enterManualy:'Enter Manualy',
        useSavedAddress:'Use Saved Address',
        newAdressSearch:'New Adress Search',
        deliveryDetails:'Mailing address',
        Organisation:'Organisation',
        door:'Door,Floor and Building or Locality',
        addressLine1:'Address Line1',
        addressLine2:'Address Line2',
        addressLine3:'Address Line3',
        Province:'Province',
        Back:'Back',
        next:'Next',
        confirmParcelTitle:'We need more information',
        saveCollection:'Save shipping address',
        saveDeliveryDetails:'Save Mailing address',
        noServiceTip:'Unfortunately we are unable to offer you a service based on the details that you have provided us.',
        NewQuote:'Get A New Quote',
        returnHomePage:'Return to Homepage',
        collectionDate:'Collection Date',
        collectionParcel1:'The collection date for your parcel is ',
        collectionParcel2:'If you would like to change this date',
        collectionParcel3:'click here',
        selectedDate:'Selected Date',
        workingDays:'Please note that delivery timescales are working days.',
        serviceParcel:'On this service deliveries can take place Monday to Friday. Please bear this in mind when estimating the delivery of your parcel.',
        autoIdentify:'Automatically identify and fill in addresses',
        textareaPlac:"You can directly paste the recipient's complete address and contact information, and the system will automatically identify and fill it in for you",
    },
}
//包裹的海关(第三步)
export const Customs={
    ZH:{
        vatStatusTrp:'请选择您的增值税状态',
        customsDetails:'海关详细信息',
        customContent:'由于您的包裹是在国际上交付的，我们需要为您生成海关文件，这需要以下详细信息才能使您的包裹通过海关',
        VAT:'增值税状态',
        exportReason:'导出原因',
        tariffCode:'包裹内物品1的税则代码和内容描述',
        tariffContent:"单击“添加另一个项目”，单独列出所有包裹项目。请确保您为每种商品输入了正确的关税代码。要帮助您找到商品的正确关税代码，请从以下类别中进行选择",
        category:'更改类别',
        recommendedCode:'这是您推荐的关税代码',
        konwTariffCode:'如果您知道关税代码，请在下面输入',
        code:'关税代码',
        change:'改变',
        save:'保存',
        quantity:'数量',
        Item:'包裹内件信息',
        Total:'总价',
        Manufacture:"制造国",
        showMore:'显示更多',
        showLess:'显示更少',
        Back:'回退',
        next:'下一步',
        toys:'玩具及游戏',
        goods:'体育物品',
        home:'家居、家具及DIY',
        book:'书籍，漫画和杂志',
        parts:'汽车零部件及配件',
        dolls:'娃娃和熊',
        games:'电子游戏和游戏机',
        health:'健康与美容',
        search:'搜索',
        Browse:'搜索海关编码',
        websiteForCode:'浏览HMRC网站获取代码',
    },
    EN:{
        vatStatusTrp:'Please select your VAT status',
        customsDetails:'Customs Details',
        customContent:'As your package is being delivered Internationally, we will need to generate customs documents for you, which will require the following details to allow your parcel to pass through customs',
        VAT:'VAT status',
        exportReason:'Export reason',
        tariffCode:'Tariff code & contents description for item 1 inside your parcel',
        tariffContent:"List all parcel items individually by clicking 'Add Another Item'. Please ensure you entered the correct tariff code for each of your items. To help you find the correct tariff code for an item, choose from one of the categories below",
        category:'Change Category',
        recommendedCode:'This is your recommended Tariff Code',
        konwTariffCode:'If you know the tariff code enter it below',
        code:'Tariff Code',
        change:'Change',
        save:'Save',
        quantity:'Quantity',
        Item:'Item',
        Total:'Total',
        Manufacture:'Country of Manufacture',
        showMore:'showMore',
        showLess:'showLess',
        Back:'Back',
        next:'Next',
        games:'Toys & Games',
        goods:'Sporting Goods',
        home:'home、Furniture & DIY',
        book:'Books,Comics & Magazines',
        parts:'Vehicle Parts & Accessories',
        dolls:'Dolls & Bears',
        games:'Wideo Games & Consoles',
        health:'Health & Beauty',
        search:'Search',
        Browse:'Browse our library',
        websiteForCode:'Browse HMRC website for code',
    },
}
//购物车(第四步)
export const basket={
    ZH:{
        title:'购物车',
        noData:'没有数据',
        clear:'清空购物车',
        edit:'编辑',
        to:'到',
        collectionAddress:'派送地址',
        deliveryAddress:'取件地址',
        fullyProtected:'完全受保护',
        price:'价格',
        voucherCode:'优惠券代码',
        breakdown:'明细',
        applyCode:'应用代码',
        subTotal:'小计',
        total:'总计',
        accept :'接受条款并立即付款',
        shopping:'去购物',
        view:'查看我们的',
        conditions:'条款和条件',
        another:'添加其他包裹',
        editParcels:'编辑包裹',
        editAddress:'编辑地址',
        changeDate:'更改收货日期',
        deleteParcel:'删除包裹',
        changeService:'改变服务',
        cditCustom:'编辑海关',
        editCargo:'编辑货物',
        deleteCargo:'删除货物',
        Pickup:'包裹将于',
        Pickup2:'到达',
        there:'共有',
        package:'个包裹',
        unProtected:'未保护',
        protected:'已保护',
        basketEmpty:'您的购物车是空的',
        otherProducts:'这意味着它充满了可能性。找到您的域名或浏览其他产品。',
    },
    EN:{
        title:'Your basket',
        noData:'No Data',
        clear:'Clear Shopping',
        edit:'Edit',
        to:'To',
        collectionAddress:'Shipping address',
        deliveryAddress:'Delivery Address',
        fullyProtected:'Fully Protected',
        price:'Price',
        voucherCode:'Voucher Code',
        breakdown:'Breakdown',
        applyCode:'Apply Code',
        subTotal:'Sub Total',
        total:'Total',
        accept:'Accept Terms and Pay Now',
        shopping:'Go to shopping',
        view:'View our',
        conditions:'terms and conditions',
        another:'Add Another Parcel',
        editParcels:'Edit Parcels',
        editAddress:'Edit Address',
        changeDate:'Change Collection Date',
        deleteParcel:'Delete Parcel',
        changeService:'Change Service',
        cditCustom:'Edit Custom',
        editCargo:'Edit Cargo',
        deleteCargo:'Delete Cargo',
        Pickup:'The package will arrive on',
        Pickup2:'reach',
        there:'There is a total of',
        package:'package',
        unProtected:'Unprotected',
        protected:'Protected',
        basketEmpty:'Your cart is empty.',
        otherProducts:"That means it's full of possibilities. Find your domain or browse other products.",
    },
}
//搜索到的包裹信息更多详情
export const parcelMoreDetails={
    ZH:{
        about:'关于此线路的服务',
        sizeAweight:'尺寸和重量限制',
        Prohibited:'禁止携带物品',
        ProhibitedContent:'我们有一份受保护的物品清单和一份我们可以保护损失但不能损坏的物品清单',
        viewList:'查看列表',
        SendUp:'每个包裹最多可发送',
        kg:'公斤.',
        maxSize:'最大尺寸:',
        reach:'到达',
        parcelProtection:'的包裹保护',
    },
    EN:{
        about:'About this Evri Drop-off Service',
        sizeAweight:'Size & weight restrictions',
        Prohibited:'Prohibited items',
        ProhibitedContent:'We have a list of prohitited items and a list of items we are able to protect for loss but not damage.',
        viewList:'View List',
        SendUp:'kg per parcel.',
        maxSize:' Max size',
        reach:'reach',
        parcelProtection:'Inclusive Parcel Protection'
    },
}
//搜索到的包裹信息
export const seconed={
    ZH:{
        Origin:'始发站',
        Destionation:'目的站',
        Date:'日期',
        ParcelDetails:'包裹详情',
        Length:'长度',
        width:'宽度',
        height:'高度',
        edit:'编辑',
        recommend:'推荐的快递公司',
        all:'全部',
        jd:'京东速运',
        sf:'顺丰',
        time:'时间',
        leave:'离开',
        arrival:'到来',
        class:'类',
        luxurious:'豪华',
        economy:'经济',
        package:'包裹',
        recommendPrice:'推荐',
        lower:'最低价格',
        highest:'最高价格',
        fast:'最快时间',
        sort:'排序',
        earliest:'最早',
        Latest:'最晚',
        option:'选择',
        number:'编号',
        weight:'重量',
        day:'天',
        hour:'小时',
        logistics:'物流公司',
        service:'服务选项',
        locker:'快递存储柜',
        collection:'快递驿站',
        drop:'自行取件',
        moreDetails:'更多详情',
        dropOff:'寻找最近的包裹地点',
        delivery:'预计到达',
        details:'详情',
        off:'附近',
        protection:'保护',
        serviceDetails:'服务细节',
        loadingMore:'点击加载更多',
        noData:'暂无数据',
        without:'预订时不带',
        with:'预定',
        Protection:'保护',
        VAT:'增值税',
        noProtection:'没有保护',
        book:'预定',
        insure:'含保险',
        unInsure:'未含保险',
        date:'取件时间',
    },
    EN:{
        Origin:'Origin',
        Destionation:'Destionation',
        Date:'Date',
        ParcelDetails:'Parcel Details',
        Length:'Length',
        width:'Width',
        height:'Height',
        edit:'Edit',
        recommend:'Recommended express company',
        all:'All',
        jd:'JD Express',
        sf:'Shunfeng',
        time:'Time',
        leave:'Departure',
        arrival:'arrival',
        class:'Class',
        luxurious:'Luxurious',
        economy:'Economy',
        package:'Express package',
        recommendPrice:'recommended',
        lower:'Lowest Price',
        highest:'Ceiling Price',
        fast:'Fastest time',
        sort:'Sort By',
        earliest:'The earliest',
        Latest:'Latest',
        option:'Option',
        number:'Number',
        weight:'Weight',
        day:'Day',
        hour:'Hour',
        logistics:'Logistics Company',
        service:'Service Options',
        locker:'Collection services',
        collection:'Drop Off services',
        drop:'Locker services',
        moreDetails:'More Details',
        dropOff:'Find Nearest Drop Off',
        delivery:'Estimated Delivery',
        details:'Details',
        off:'Drop Off',
        protection:'Protection',
        serviceDetails:'Service Details',
        loadingMore:'Click to load more',
        noData:'No Data',
        without:'Book Without',
        with:'With',
        Protection:'Protection',
        VAT:'VAT',
        noProtection:'No protection',
        book:'Book',
        insure:'Including insurance',
        unInsure:'Excluding insurance',
        date:'Pickup',
    }
}
//支付
export const payment={
    ZH:{
        yue:'余额',
        card:'银行卡',
        wechart:'微信',
        alipay:'支付宝',
        balancePay:'余额支付',
        cardPay:'银行卡支付',
        cardTransfer:'银行转账支付',
        wxPay:'微信支付',
        alipayPay:'支付宝支付',
        balanceContent:'随时随地一键支付，操作简单，方便快捷',
        cartContent:'支持各类信用卡和借记卡，即时到账，方便快捷',
        cartTransferContent:'支持各类信用卡和借记卡转账',
        wxContent:'支持通过微信扫码进行支付',
        alipayContent:'支持通过支付宝扫码进行支付',
        subTotal:'小计',
        vat:'增值税',
        total:'总计',
        Breakdown:'明细',
        pay:'支付',
        transfer:'转账',
        addFound:'添加要预付的金额',
        Continue:'继续付款',
        thanks:'谢谢你',
        payContent:'您的预付款已收到并存入预付款账户。若要检查您的余额，只需登录您的账户，您的预付费余额将显示在登录区域的右上角',
        deposit:'谢谢您的存款',
        Ok:'好的',
        checkout:'客人结账',
        checking:'你是以客人身份登录的。你可以',
        loginNow:'立即登录',
        register:'或在完成付款后注册，此预订将添加到您的订单历史记录中',
        PrePay:'余额',
        upPrepay:'充值',
    },
    EN:{
        yue:'Remaining amount',
        card:'Bank card',
        wechart:'WeChat',
        alipay:'Alipay',
        balancePay:'Prepay Balance',
        cardPay:'Card payment',
        cardTransfer:'Bank transfer payment',
        wxPay:'Pay with Wechat',
        alipayPay:'Alipay',
        balanceContent:'One click payment anytime, anywhere, easy to operate, convenient and fast',
        cartContent:'We support Visa,Mastercard,  Discover and 银联',
        cartTransferContent:'Support various credit and debit card transfers',
        wxContent:'Support for payment through WeChat scanning',
        alipayContent:'Support payment by scanning Alipay code',
        subTotal:'Net Price',
        vat:'VAT',
        total:'Total',
        Breakdown:'Breakdown',
        pay:'Pay',
        transfer:'Transfer accounts',
        addFound:'Add founds to prepay',
        Continue:'Continue To Payment',
        thanks:'Thank You',
        payContent:'Your prepay funds have been received and deposited into your prepay account, To check your balance, simplsign in to your account and your prepay balance will appear on the top right hand side of your signed in area',
        deposit:'Thank you for your deposit of ',
        Ok:'Ok',
        checkout:'Guest checkout',
        checking:'You are checking out as a guest. You can',
        loginNow:'login now',
        register:'or register after completing your payment wherethis booking will be added to your order history',
        PrePay:'PrePay',
        upPrepay:'up prepay',
    }
}
//支付后的打印标签
export const readyLabel={
    ZH:{
        getting:'我们正在准备您的标签',
        download:'它们将很快准备好下载',
        Depot:'仓库',
        dropOff:'减少',
        opted:'您已选择将包裹放在',
        Tracking:'追踪',
    },
    EN:{
        getting:"We're just getting your labels ready",
        download:'They will be ready to download shortly',
        Depot:'Depot',
        dropOff:'Drop Off',
        opted:'You have opted to drop your parcel off at',
        Tracking:'Tracking',
    },
}
//订单预定成功
export const orderBooked={
    ZH:{
        title:'您的订单已被预订',
        orderReady:'准备好打包包裹并贴上标签，以便快递。下载下面的所有文件，或者前往我们的账户打印任何所需的文件',
        Download:'下载',
        Print:'ZPL打印',
    },
    EN:{
        title:'Your order has been booked',
        orderReady:'Get ready to pack and label your parcels for their delivery. Download all paperwork below or alternatively head toyour account to print any required paperwork',
        Download:'Download',
        Print:'ZPL Print',
    },
}
//批量下单
export const mutilpleParcel={
    ZH:{
        title:'批量下单',
        content:'想知道寄包裹要花多少钱吗？使用我们快速简便的包裹报价工具，了解在英国境内发送或使用国际航运服务的费用。只需输入目的地信息、您发送的包裹数量和包裹尺寸即可获得免费包裹递送报价。',
        origin:'出发地',
        Destination:'目的地',
        date:'时间',
        Quantity:'数量',
        width:'宽',
        length:'长',
        height:'高',
        weight:'重量',
        addAonther:'添加其他包裹',
        Duplicate:'复制包裹',
        Quote:'获取报价',
        title2:'快速下单',
        mutipleContent:'或者，如果您没有使用上述平台之一来销售您的商品，您可以下载我们的',
        CSV:"CSV模板",
        continueContent:'只需填写模板并上传到我们的网站即可。然后，我们将根据您提供的信息为您创建多个预订——您所需要做的就是检查您是否满意并确认。',
        click:'您可以点击',
        here:'这里',
        orders:'下批量订单',
        remove:'删除包裹',
    },
    EN:{
        title:'Batch order placement',
        content:'Looking to understand how much it will cost to send your parcel? Use our quick and easy parcel quote tool to find out how much sending within the UK or utilising international shipping services will cost. Simply enter the destination information, the number of packages that you are sending and the size of the parcel for a free parcel delivery quote.',
        origin:'Origin',
        Destination:'Destination',
        date:'Date',
        Quantity:'Quantity',
        width:'Width',
        length:'Length',
        height:'Height',
        weight:'Weight',
        addAonther:'Add Another Parcel',
        Duplicate:'Duplicate Parcel',
        Quote:'Quote & Book',
        title2:'Send mutiple parcel',
        mutipleContent:'Alternatively, if you aren’t using one of the above platforms to sell your goods, you can download our',
        CSV:"CSV template",
        continueContent:'Simply fill in the template and upload to the DHL Parcel UK website. We will then create multiple bookings for you based on the information you provide – all you need to do is check you are happy and confirm.',
        click:'You can click',
        here:'here',
        orders:'to place bulk orders',
        remove:'Remove Parcel',
    },
}
//快速下单
export const quickOrder={
    ZH:{
        quickOrderTitle:'欢迎使用快速发送',
        quickOrderContent:'您可以自动从10多个不同的市场和电子商务网站导入已售出的商品。请注意，只有在过去14天内进口的商品才会在下面显示',
        sending:'您没有需要发送的项目。。。',
        add:'添加新的',
        order:'我的订单',
        upload:'上传电子表格',
        items:'还原项目',
        Loading:'正在加载已售出的商品。。。',
        Restore:'恢复上载的项目',
        Search:'搜索',
        matchSearch:'没有与您的搜索匹配的项目。',
        Delivery:'交货详细信息',
        Name:'派送人姓名',
        Country:'交货国家/地区',
        parcelDetails:'新项目的包裹详细信息',
        Countents:'地块计数',
        itemValue:'单价',
        Quantity:'数量',
        Dimensions:'设置尺寸',
        Cancel:'取消',
    },
    EN:{
        quickOrderTitle:'Welcome to quick send',
        quickOrderContent:'You can automatically import your sold items from over 10 different marketplace and ecommerce sites. Please note only items imported in the last 14 days will be visible below',
        sending:'You have no items that need sending...',
        add:'add New',
        order:'My Order',
        upload:'Upload Spreadsheet',
        items:'Restore Items',
        Loading:'Loading your sold items...',
        Restore:'Restore uploaded items',
        Search:'Search',
        matchSearch:'There are no items that match your search.',
        Delivery:'Delivery details',
        Name:'Recipient Name',
        Country:'Delivery Country',
        parcelDetails:'Parcel details for new item',
        Countents:'Parcel Countents',
        itemValue:'Item Value',
        Quantity:'Quantity',
        Dimensions:'Set Dimensions',
        Cancel:'Cancel',
    },
}
//上传电子表格
export const uploadCsv={
    ZH:{
        title:'在此处上传您的xlsx',
        content:'我们的xlsx上传工具非常灵活，允许您通过将列映射到我们需要的信息来上传任何电子表格文件。',
        download:'您可以下载一个示例文件',
        here:'这里',
        select:'选择xlsx文件',
        file:'文件有列标题',
        upload:'上传我的xlsx',
        DimensionsCM:'尺寸单位：CM',
        DimensionsM:'尺寸单位：M',
    },
    EN:{
        title:'Upload your xlsx here',
        content:'Our xlsx upload tool is flexible and allows you to upload any spreadsheet file by mapping your columns to the information that we require.',
        download:'You can download an example file',
        here:'here',
        select:'Select xlsx File',
        file:'File has column headers',
        upload:'Upload My xlsx',
        DimensionsCM:'Dimensions in CM',
        DimensionsM:'Dimensions in M',
    },
}
//批量下单
export const quickSend={
    ZH:{
        all:'全部查看',
        read:'已读取',
        notRead:'未读取',
        orders:'分组订单',
        addBasket:'添加到购物车',
        deleteItem:'删除项目',
        addParcel:'添加新包裹',
        linked:'绑定的账户',
        upload:'上传电子表格',
        Delivery:'交货详细信息',
        Recipient:'派送人姓名',
        Country:'交货国家/地区',
        newItem:'新项目的包裹详细信息',
        Countents:'包裹计数',
        itemValue:'单价',
        Quantity:'数量',
        Dimensions:'设置尺寸',
        Cancel:'取消',
        drop:'在商店卸货',
        days:'天',
        enterPostcode:'输入邮政编码以查看离您最近的服务点。',
        Printer:'需要打印机 | 包括50英镑的封面 |',
        more:'更多信息',
        buyNow:'立即购买',
        Choose:'选择重量和尺寸/盒子尺寸',
        introduce:'您最多可以发送30公斤，包裹的一侧不得超过120厘米，另两侧不得超过50厘米。',
        Weight:'重量',
        Length:'长度',
        Width:'宽度',
        Height:'高度',
        Change:'更改',
        Sending:'要发送的项目',
        noData:'暂无数据',
        shopping:'去购物',
        Value:'价值',
        chooseServiceTitle:'请为您的包裹选择服务.',
        chooseService:'选择服务',
        changeSize:'更改尺寸',
        delete:'删除',
        Purchased:'购买',
        save:'保存到很久',
        vat:'增值税收入',
        Collection:'收货地址',
        Address:'地址',
        Edit:'编辑',
        Instructions:'说明书',
        Email:'邮箱',
        PhoneNumber:'手机号',
        Search:'搜索',
        Manualy:'输入手册',
        userAddress:'使用保存的地址',
        addressSearch:'地址智能搜索',
        saveAddress:'保存地址',
        addItems:'为所有物品添加额外功能',
        Full:'完全保护',
        fullProtextion:'在可用的情况下，提供了全面保护',
        DeliverySignature:'交付签名',
        domestic:'为所有国内项目添加签名',
        add:'添加',
        remove:'移除',
        Price:'价格细目',
        Base:'基价',
        selected:'已选择的项目',
        remain:'个项目会一直呆到下次',
        Total:'总价',
        Close:'关闭',
        Continue:'继续',
        ImportSources:'导入来源',
        Services:'服务',
        Add:'添加',
        Actions:'操作',
        coverPrice:'价格',
    },
    EN:{
        all:' View All',
        read:'Ready',
        notRead:'Not Ready',
        orders:'Group Orders',
        addBasket:'Add to Basket',
        deleteItem:'Delete Items',
        addParcel:'Add New Parcel',
        linked:'Linked Accounts',
        upload:'Upload A Spreadsheet',
        Delivery:'Delivery details',
        Recipient:'Recipient Name',
        Country:'Delivery Country',
        newItem:'Parcel details for new item',
        Countents:'Parcel Countents',
        itemValue:'Item Value',
        Quantity:'Quantity',
        Dimensions:'Set Dimensions',
        Cancel:'Cancel',
        drop:'DROP AT SHOP',
        days:'DAYS',
        enterPostcode:'Enter postcode to see your closest ServicePoint.',
        Printer:'Printer required | £50 cover included |',
        more:'More Info',
        buyNow:'Buy Now',
        Choose:'Choose weight and size/box size',
        introduce:'You can send up to 30kg and no one side of your parcel must exceed 120cm and the other two sides cannot exceed 50cm.',
        Weight:'Weight',
        Length:'Length',
        Width:'Width',
        Height:'Height',
        Change:'Change',
        Sending:'Items For Sending',
        noData:'No Data',
        shopping:'Go to shopping',
        Value:'Value',
        chooseServiceTitle:'Please choose a service for your parcel.',
        chooseService:'Choose Service',
        changeSize:'Change Size',
        delete:'Delete',
        Purchased:'Purchased',
        save:'Save For Late',
        vat:'inc Vat',
        Collection:'Collection Address',
        Address:'Address',
        Edit:'Edit',
        Instructions:'Instructions',
        Email:'Email',
        PhoneNumber:'Phone Number',
        Search:'Search',
        Manualy:'Enter Manualy',
        userAddress:'Use Saved Address',
        addressSearch:'New Adress Search',
        saveAddress:'Save Address',
        addItems:'ADD EXTRA FEATURES TO ALL YOUR ITEMS',
        Full:'Full Protection',
        fullProtextion:'Full protection has been included,where available',
        DeliverySignature:'Delivery Signature',
        domestic:'Add a signature to all domestic items for',
        add:'Add',
        remove:'Remove',
        Price:'Price Breakdown',
        Base:'Base Price',
        selected:'items selected',
        remain:' will remain here until next time',
        Total:'Total Price',
        Close:'Close',
        Continue:'Continue',
        ImportSources:'Import Sources',
        Services:'Services',
        Add:'Add',
        Actions:'Actions',
        coverPrice:'Price',
    },
}