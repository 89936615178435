const track = [
    {
        path:'searchOrder',
        name:'searchOrder',
        component:()=>import('@/views/track/components/searchOrder.vue'),
        meta:{
            
        }
    }
]
export default track