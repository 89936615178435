import { getCookie,setCookie } from '@/utils/cookie'
let parcel = {
	/* eslint-disable */
	namespaced: true,
	state: {
		parcelSetup:0,
		addressData:'',
		parcelDetails:'',
		planParcelData:'',
		parcelId:'',//包裹信息
		parcelId2:getCookie('parcelId2')||'',//包裹的唯一id
		protece_price:'',
		getParcelQuote:'',
		parcelExpress:'',
		parcelConfirm:[],//地址错误进行修改的字段
		errorParcelInfo:'',//错误的包裹信息
		OrderNo:'',//订单号
		parcelPrice:'',//包裹的价格
		basketId:[],//在购物车中已选中的id
		multipleParcelData:[],//批量下单的数据
		payShoppingOrder:[],//在购物车中支付的订单信息
		expressDetails:{},//获取报价选中某一条的数据
		parcelSku:[],//包裹内物品的数据(获取sku)
		pickUpDate:'',//自行取件的日期
		InvalidPickupDate:[],//取件日期失效的购物车id
		addressNo:[],//购物车地址有误的id
		reOrderNo:[],//再次购买的包裹id
	},
	mutations: {
		storagereOrderNo(state,val){//存储购物车地址有误的购物车id
            state.reOrderNo = val
        },
		storageAddressNo(state,val){//存储购物车地址有误的购物车id
            state.addressNo = val
        },
		stroageInvalidPickupDate(state,val){//存储取件日期失效的id
			state.InvalidPickupDate = val
		},
		//存储自行取件的日期
		storagepickUpDate(state,data){
			state.pickUpDate = data
		},
		//存储包裹内物品
		storageParcelSku(state,data){
			state.parcelSku.push(data)
		},
		//将获取报价选中的某一条数据存在vuex中
		storageExpressDetails(state,data){
			state.expressDetails = data
		},
		//存储选中购物车的商品
		storagePayShoppingOrder(state,data){
			state.payShoppingOrder = data
		},
		//存储批量下单的数据
		storageCsvParcelInfo(state,data){
			state.multipleParcelData = data
		},
		//存储购物车的id数组
		storageBasketId(state,data){
			state.basketId = data
		},
		//包裹的步骤条
		setup(state,index){
            state.parcelSetup=index
        },
		//保存包裹地址的信息
		storageParcelAddress(state,data){
			console.log(data);
			state.addressData=data
		},
		//保存预定包裹的所有信息
		storageParcelDetails(state,data){
			state.parcelDetails=data
		},
		//保存plan模块的包裹信息
		storagePlanParcelData(state,data){
			console.log(data);
			state.planParcelData=data
		},
		//是否为受保护的包裹id和包裹公司id
		storageParcelId(state,data){
			state.parcelId=data
		},
		//修改是否为保护的包裹id
		changeParcelId(state,data){
			console.log(state.parcelId,data);
			try {
				state.parcelId[0]=data
				let parcelInfo = JSON.parse(sessionStorage.getItem('parcelId'))
				parcelInfo[0]=data
			} catch (error) {
				return error
			}
		},
		//存入获取的包裹唯一id值
		storageParcelId2(state,data){
			setCookie('parcelId2',data)
			state.parcelId2=data
		},
		//存入包裹的保护价格
		storageProtecePrice(state,data){
			state.protece_price=data
		},
		//将获取报价的公共参数保存(首重价格/续重价格/value的保护费率/增值税率)
		storageGetParcelQuote(state,data){
			state.getParcelQuote=data
		},
		//保存包裹的快递公司和logo
		storageParcelExpress(state,data){
			console.log(data);
			state.parcelExpress=data
		},
		//修改包裹的字段
		changeParcelConfirm(state,data){
			state.parcelConfirm=data
		},
		//保存错误的包裹信息
		storageErrorParcelInfo(state,data){
			state.errorParcelInfo=data
		},
		//下完单之后的订单号
		storageOrderNo(state,data){
			localStorage.setItem('OrderNo',JSON.stringify(data))
			state.OrderNo=data
		},
		//保存包裹的价格
		storageParcelPrice(state,data){
			state.parcelPrice=data
		}
	}
};
export default parcel;
