let user = {
	/* eslint-disable */
	namespaced: true,
	state: {
		orderDetails:{},//存储订单信息
	},
	mutations: {
		storageOrdeDetails(state,data){
			state.orderDetails=data
		},
	},
};
export default user;