let person = {
	/* eslint-disable */
	namespaced: true,
	state: {
		accountPay:'',
	},
	mutations: {
        //存储充值账户的信息
		setAccountPay(state,data){
			data=data.replace(/[^\d]/g,'')
            state.accountPay=data
        },
	}
};
export default person;
