import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin'
import parcel from './modules/parcel'
import person from './modules/person'
import service from './modules/service'
import cargo from './modules/cargo'
import flight from './modules/flight'
import track from './modules/track'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    language:localStorage.getItem('languageSet')||'zh'
  },
  mutations:{
    languageSet(state,language){
      state.language = language
      localStorage.setItem('languageSet',language)
    },
  },
  modules: {
    admin,parcel,person,service,flight,cargo,track,user
  }
})
