const cargo=[
    {
        path:'/cargoQuotation',
        name:'cargoQuotation',
        component:()=>import('@/views/cargo/cargoQuotation.vue'),
        meta:{
            // title:'空运报价'
        }
    },
    {
        path:'/cargoAnother',
        name:'cargoAnother',
        component:()=>import('@/views/cargo/components/cargoAnother.vue'),
        meta:{
            // title:'空运'
        }
    },
    {
        path:'cargoDetails',
        name:'cargoDetails',
        component:()=>import('@/views/cargo/components/cargoDetails.vue'),
        meta:{
            // title:'空运信息',
            secondaryPage:true
        }
    },
    {
        path:'cargoAddress',
        name:'cargoAddress',
        component:()=>import('@/views/cargo/components/addressDetails.vue'),
        meta:{
            // title:'空运地址',
            secondaryPage:true
        }
    },
    {
        path:'cargoCustom',
        name:'cargoCustom',
        component:()=>import('@/views/cargo/components/cargoCustom.vue'),
        meta:{
            // title:'空运海关',
            secondaryPage:true
        }
    },
    {
        path:'cargoBasket',
        name:'cargoBasket',
        component:()=>import('@/views/cargo/components/cargoBasket.vue'),
        meta:{
            // title:'空运购物车',
            secondaryPage:true,
        }
    },
    {
        path:'cargoPayment',
        name:'cargoPayment',
        component:()=>import('@/views/cargo/components/cargoPaymentView.vue'),
        meta:{
            // title:'支付',
            parcelSetup:true,
            parcelShop:true,
            secondaryPage:true
        }
      },
]
export default cargo