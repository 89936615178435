//cargo的二级页面
export const cargoStatus={
    ZH:{
        status:'货运状态'
    },
    EN:{
        status:'Cargo Status'
    }
}
//cargo的planview翻译
export const cargoPlan = {
    ZH:{
        adHoc:'特货申请',
        tableAutofill:'表格自动识别',
        selectAutofile:'选择以下选项之一以自动填充尺寸表：',
        copyText:'复制粘贴你的装箱单文本',
        Cancel:'取消',
        submit:'提交',
        downloadExcel:'下载Excel模板',
        Generalcargo:'普通货物',
        DangerousGoods:'危险货物',
        Perishable:'易腐货物',
        Yes:'是',
        No:'否',
        unId:'UN/ID编号',
        classDiv:'类/DIV',
        UOM:'总净数量',
        temp:'温度要求',
        tempNo:'不需要温度控制',
        shipment:'您的货物需要冷却包装吗？',
        device:'有温度跟踪装置吗？',
        NotRequired:'不需要',
        IceWater:'冰水',
        DryIce:'干冰',
    },
    EN:{
        adHoc:'Ad hoc',
        tableAutofill:'Table Autofill',
        selectAutofile:'Select one of the choices below to autofill the dimensions table :',
        copyText:'COPY-PASTE YOUR PACKING LIST TEXT',
        Cancel:'Cancel',
        submit:'Submit',
        downloadExcel:'Download excel template',
        Generalcargo:'General Cargo',
        DangerousGoods:'Dangerous Goods',
        Perishable:'Perishable',
        Yes:'Yes',
        No:'No',
        unId:'UN/ID NO',
        classDiv:'CLASS/DIV',
        UOM:'TOTALNETQTY UOM',
        temp:'TEMPERATURE REQUIREMENTS',
        tempNo:'Temperature control not required',
        shipment:'Does your shipment require cooling package ?',
        device:'ANY TEMPERATURE TRACKING DEVICE ?',
        NotRequired:'Not Required',
        IceWater:'Ice Water',
        DryIce:'Dry Ice',
    }
}
// cargo详情(第一步)
export const cargoDetails = {
    ZH:{
        oneTitle:'货运详情',
        protection:'货运保护',
        Pallet:'托盘',
        Cartons:"纸箱",
        Crates:'板条箱',
        Drum:'大桶',
        package:'包装',
        goodsType:'货物类型',
    },
    EN:{
        oneTitle:'Cargo Details',
        protection:'Freight protection',
        Pallet:'Pallet',
        Cartons:"Cartons",
        Crates:'Crates',
        Drum:'Drum',
        package:'Package',
        goodsType:'Goods Type',
    },
}