import {navbar,person,member,footer,navbarRight} from './data/navbar'
import {orderTitle,order,walletTitle,accountTitle,login,paynow,
        wallet,accountManage,accountSet,safe,address,packingList,
        payAccount,applyLog,invoice,link,orderall,realList,parcelList,
        realName,} from './data/user'
import { parcelStatus,seconed,payment,parcelDetails,Customs,sideBarRight,parcelSetup,
        addressDetails,basket,readyLabel,orderBooked,mutilpleParcel,quickOrder,uploadCsv,
        quickSend,parcelMoreDetails } from './data/parcel'
import { search,plan,home,introduce,cookie } from './data/home'
import {cargoStatus,cargoPlan,cargoDetails} from './data/cargo'
const zh = {
    language: {
        name: 'zh'
    },
    navbar:{
        navbar:navbar.ZH,
        person:person.ZH,
        member:member.ZH,
        footer:footer.ZH,
        navbarRight:navbarRight.ZH,
    },
    user:{
        orderTitle:orderTitle.ZH,
        order:order.ZH,
        walletTitle:walletTitle.ZH,
        accountTitle:accountTitle.ZH,
        login:login.ZH,
        wallet:wallet.ZH,
        accountManage:accountManage.ZH,
        accountSet:accountSet.ZH,
        safe:safe.ZH,
        address:address.ZH,
        packingList:packingList.ZH,
        paynow:paynow.ZH,
        payAccount:payAccount.ZH,
        applyLog:applyLog.ZH,
        invoice:invoice.ZH,
        link:link.ZH,
        orderall:orderall.ZH,
        realList:realList.ZH,
        parcelList:parcelList.ZH,
        realName:realName.ZH,
    },
    parcel:{
        parcelStatus:parcelStatus.ZH,
        seconed:seconed.ZH,
        payment:payment.ZH,
        parcelDetails:parcelDetails.ZH,
        Customs:Customs.ZH,
        sideBarRight:sideBarRight.ZH,
        parcelSetup:parcelSetup.ZH,
        addressDetails:addressDetails.ZH,
        basket:basket.ZH,
        readyLabel:readyLabel.ZH,
        orderBooked:orderBooked.ZH,
        mutilpleParcel:mutilpleParcel.ZH,
        quickOrder:quickOrder.ZH,
        uploadCsv:uploadCsv.ZH,
        quickSend:quickSend.ZH,
        parcelMoreDetails:parcelMoreDetails.ZH,
    },
    cargo:{
        cargoStatus:cargoStatus.ZH,
        cargoPlan:cargoPlan.ZH,
        cargoDetails:cargoDetails.ZH,
    },
    home:{
        search:search.ZH,
        plan:plan.ZH,
        home:home.ZH,
        introduce:introduce.ZH,
        cookie:cookie.ZH,
    }
}
export default zh  //暴露出去