const flights=[
    {
        path:'/flightInfo',
        name:'readyflightInfo',
        // component:()=>import('@/views/flight/flightInfo.vue'),
        meta:{
            // title:'航空信息'
        }
    },
]
export default flights