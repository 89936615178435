const footer = [
    {
        path:'aboutUs',
        name:'aboutUs',
        component:()=>import('@/views/footer/components/aboutUs.vue'),
        meta:{
            top:true,
        }
    },
    {
        path:'News',
        name:'News',
        component:()=>import('@/views/footer/components/News.vue'),
        meta:{
             top:true,
        }
    },
    {
        path:'privacyAndCookies',
        name:'privacyAndCookies',
        component:()=>import('@/views/footer/components/privacyAndCookies.vue'),
        meta:{
             top:true,
             footerContent:true,
        }
    },
    {
        path:'TermsAndConditions',
        name:'TermsAndConditions',
        component:()=>import('@/views/footer/components/TermsAndConditions.vue'),
        meta:{
             top:true,
             footerContent:true,
        }
    },
    {
        path:'become',
        name:'become',
        component:()=>import('@/views/footer/components/otherService/become.vue'),
        meta:{
             top:true,
        }
    },
    {
        path:'refundPolicy',
        name:'refundPolicy',
        component:()=>import('@/views/footer/components/otherService/refundPolicy.vue'),
        meta:{
             top:true,
        }
    },
    {
        path:'contactUs',
        name:'contactUs',
        component:()=>import('@/views/footer/components/contactUs/contactUs.vue'),
        meta:{
             top:true,
        }
    },
]
export default footer