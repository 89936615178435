import axios from 'axios'
import router from '@/router';
// import baseURL from './baseURL'
// import qs from 'qs'
import { Loading } from 'element-ui'
import Message from '@/utils/message'
import store from '@/store'
import { getCookie } from '@/utils/cookie'
let count = 0;
const request = axios.create({
  baseURL:process.env.VUE_APP_BASEURL,
  // baseURL: process.env.VUE_APP_WeChartURL,
  // timeout: 10000,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
    } 
  })
let loadingInstance1;
let skipLoading=["/api/Chatbot/openapiAibot"]
let skipLoading2 = ["api/Parcel/getAddress"]
// 请求拦截
request.interceptors.request.use(
  async config => {
    // console.log(config);
    // config.data = qs.stringify(config.data)
    if(skipLoading.some(x=>config.url.includes(x))){
      return config
    }
    if(!skipLoading2.some(x=>config.url.includes(x))&&!count){
      loadingInstance1 = await Loading.service({
        fullscreen:true,
        background:'rgba(0, 0, 0, 0.8)',
        spinner:'',
      });
    }
    count++
    console.log('请求');
    // config.headers.token=store.state.admin.token
    config.headers.token=getCookie('token')
    return config
  },
  error => {
    loadingInstance1.close()
    return Promise.reject(error)
  })

// // 响应拦截
request.interceptors.response.use(
  async response => {
    count--
    if(count==0){
      loadingInstance1.close()
    }
    console.log('响应');
    return response
  },
  error => {
    if(!error.response){
      Message.error(error.message)
    }
    loadingInstance1.close()
    console.log(error);
    if(error.response.status==401){
      localStorage.removeItem('token')
      Message.error('登陆失效,请重新登录')
      setTimeout(async() => {
        store.commit('admin/logOut')
        await router.push('/home')
        await location.reload()
      }, 1500);
      return
    }
    return Promise.reject(error.response.status)
  })

export default request
