let flight = {
	/* eslint-disable */
	namespaced: true,
	state: {
		aviationInformation:null
	},
	mutations: {
		storageInfo(state,info){
			state.aviationInformation=info
		},
	},
};
export default flight;
