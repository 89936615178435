import Vue from 'vue'
//中英文切换
import VueI18n from 'vue-i18n'
import ZH from './zh'
import EN from './en'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        en:{
            ...EN,
            ...enLocale
        },
        zh:{
            ...ZH,
            ...zhLocale
        },
    }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n