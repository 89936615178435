let cargo = {
	/* eslint-disable */
	namespaced: true,
	state: {
		cargoSetup:0,
        planAirData:'',
	},
	mutations: {
		//包裹的步骤条
		setup(state,index){
			state.cargoSetup=index
		},
        //保存plan模块的空运信息
        storagePlanAirData(state,data){
			if(!data[1]){
				state.planAirData=data
				return
			}
			state.planAirData.dimensions=data[0]
        },
	},
};
export default cargo;
