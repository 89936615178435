const other=[
    {
        path:'/cancelOrder',
        name:'cancelOrder',
        component:()=>import('@/views/user/componends/cancleOrder.vue'),
        meta:{
            // title:'取消订单'
            top:true,
        }
    },
    {
        path:'/refundRequest',
        name:'refundRequest',
        component:()=>import('@/views/user/componends/refundRequest.vue'),
        meta:{
            // title:'退款请求'
            top:true,
        }
    },
]
export default other