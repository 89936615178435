<template>
  <div>
    <KeepAlive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </KeepAlive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <transition name="fade">
      <div class="cookie" v-if="!storageAdmin.cookieStorage&&!closeCookie">
        <div class="cookieContent">
        <div class="icon iconfont icon-cuowu" @click="closeCookie=true"></div>
          <h2 style="margin-bottom:10px;">{{ $t('home.cookie.title') }}</h2>
          <div class="flex-center">
            <div style="flex:1;">{{ $t('home.cookie.content') }}</div>
            <div class="AcceptCookie" @click="Accept">
              {{ $t('home.cookie.Accept') }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getCookie,setCookie } from '@/utils/cookie'
export default {
  data(){
    return{
      timer:null,
      closeCookie:false,
    }
  },
  computed:{
    ...mapState({storageAdmin:'admin'}),
  },
  methods:{
    startInactivityTimer() {//1小时没有操作退出登录
      this.timer = setTimeout(async ()=>{
        if (this.storageAdmin.token) {
          let { data } = await this.$api.logout();
          if (data.code == 1) {
            this.$store.commit('admin/logOut')
            this.$router.push("/home");
          } else {
            this.$message.error(data.msg);
          }
        }
        // 3600000
      }, 3600000);
    },
    resetInactivityTimer() {//重置时间
      clearTimeout(this.timer);
      this.startInactivityTimer();
    },
    //同意存储到cookie
    Accept(){
      this.$store.commit('admin/storageCookie',true)
    },
  },
  mounted(){
    document.addEventListener('mousemove', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    }
    // sessionStorage.removeItem("store")
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload",()=>{
      this.$store.state.admin.token = getCookie('token')
      this.$store.state.admin.userInfo = ''
      sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  },
}
</script>

<style lang="less" scoped>
.cookie{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: #f7f7f7;
  z-index: 999;
  .cookieContent{
    width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    line-height: 25px;
    .icon{
      float:right;
      font-size:25px;
      cursor: pointer;
    }
    .AcceptCookie{
      width: fit-content;
      margin-left: 40px;
      padding: 10px 30px;
      background: #0071c2;
      cursor: pointer;
      color: white;
    }
  }
}
.fade-enter { transform: translateY(-100%); }
.fade-enter-active,.fade-leave-active  {transition: all 1s cubic-bezier(.19,1,.22,1); }
.fade-leave-to { transform: translateY(100%); }
</style>