import {navbar,person,member,footer,navbarRight} from './data/navbar'
import {orderTitle,order,walletTitle,accountTitle,login,paynow,
        wallet,accountManage,accountSet,safe,address,packingList,
        payAccount,applyLog,invoice,link,orderall,realList,parcelList,
        realName,} from './data/user'
import { parcelStatus,seconed,payment,parcelDetails,Customs,sideBarRight,parcelSetup,
        addressDetails,basket,readyLabel,orderBooked,mutilpleParcel,quickOrder,uploadCsv,
        quickSend,parcelMoreDetails } from './data/parcel'
import {search,plan,home,introduce,cookie} from './data/home'
import {cargoStatus,cargoPlan,cargoDetails} from './data/cargo'
const en = {
    language: {
        name: 'en'
    },
    navbar:{
        navbar:navbar.EN,
        person:person.EN,
        member:member.EN,
        footer:footer.EN,
        navbarRight:navbarRight.EN,
    },
    user:{
        orderTitle:orderTitle.EN,
        order:order.EN,
        walletTitle:walletTitle.EN,
        accountTitle:accountTitle.EN,
        login:login.EN,
        wallet:wallet.EN,
        accountManage:accountManage.EN,
        accountSet:accountSet.EN,
        safe:safe.EN,
        address:address.EN,
        packingList:packingList.EN,
        paynow:paynow.EN,
        payAccount:payAccount.EN,
        applyLog:applyLog.EN,
        invoice:invoice.EN,
        link:link.EN,
        orderall:orderall.EN,
        realList:realList.EN,
        parcelList:parcelList.EN,
        realName:realName.EN,
    },
    parcel:{
        parcelStatus:parcelStatus.EN,
        seconed:seconed.EN,
        payment:payment.EN,
        parcelDetails:parcelDetails.EN,
        Customs:Customs.EN,
        sideBarRight:sideBarRight.EN,
        parcelSetup:parcelSetup.EN,
        addressDetails:addressDetails.EN,
        basket:basket.EN,
        readyLabel:readyLabel.EN,
        orderBooked:orderBooked.EN,
        mutilpleParcel:mutilpleParcel.EN,
        quickOrder:quickOrder.EN,
        uploadCsv:uploadCsv.EN,
        quickSend:quickSend.EN,
        parcelMoreDetails:parcelMoreDetails.EN,
    },
    cargo:{
        cargoStatus:cargoStatus.EN,
        cargoPlan:cargoPlan.EN,
        cargoDetails:cargoDetails.EN,
    },
    home:{
        search:search.EN,
        plan:plan.EN,
        home:home.EN,
        introduce:introduce.EN,
        cookie:cookie.EN,
    }
}
export default en  //暴露出去