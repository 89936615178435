//获取cookie
export const getCookie = (name)=> {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
        }
    }
    return null;
}
//设置cookie
export const setCookie = (name,value,status) =>{
    const expirationDate = new Date('9999-12-31');
    if(status){
        document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;
    }else{
        document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/`;
    }
}