import request from "./request";
// export const platid='MDAwMDAwMDAwMLOcdnU';//分平台的平台id
export const platid='MDAwMDAwMDAwMLOcdnU';//分平台的平台id
let lang=localStorage.getItem('languageSet')||'zh'
/* 登陆注册忘记密码退出登录 */

const emailRegister=(data)=>request.post(`/api/user/emailRegister?platid=${platid}&lang=${lang}`,data)//邮箱的注册
const sendEmailCode=(data)=>request.post(`/api/user/sendEmailCode?platid=${platid}&lang=${lang}`,data)//发送邮箱验证码
const mobileLogin = (data)=>request.post(`/api/user/mobileLogin?platid=${platid}&lang=${lang}`,data)//手机登录
const mobileRegister=(data)=>request.post(`/api/user/mobileRegister?platid=${platid}&lang=${lang}`,data)//手机号的注册
const sendMobileCode=(data)=>request.post(`/api/user/sendMobileCode?platid=${platid}&lang=${lang}`,data)//发送手机验证码
const logout=(data)=>request.post(`/api/user/logout?platid=${platid}&lang=${lang}`,data)//退出登录
const checkEmail=(data)=>request.post(`/api/user/checkEmail?platid=${platid}&lang=${lang}`,data)//验证邮箱是否存在
const emailPwdLogin=(data)=>request.post(`/api/user/emailPwdLogin?platid=${platid}&lang=${lang}`,data)//邮箱密码登录/注册
const emailLogin = (data)=>request.post(`/api/user/emailLogin?platid=${platid}&lang=${lang}`,data)//邮箱验证码登录
const forgotPwd = (data)=>request.post(`/api/user/forgotPwd?platid=${platid}&lang=${lang}`,data)//忘记密码，重新设置密码

/* 客服 */
const openapiSign=(data)=>request.post(`/api/Chatbot/openapiSign?platid=${platid}&lang=${lang}`,data)//获取openapiSign
const openapiAibot=(data)=>request.post(`/api/Chatbot/openapiAibot?platid=${platid}&lang=${lang}`,data)//智能聊天

/*  首页  */ 
const logoImg = () => request.get(`/api/index/config?platid=${platid}&lang=${lang}`) // 获取logo
const switchLanguage = (data) => request.post(`/api/index/switchLanguage?platid=${platid}&lang=${lang}`,data) // 切换语言
const getUniqid = () => request.get(`/api/index/getUniqid?platid=${platid}&lang=${lang}`) // 获取唯一值

/* 公共 */ 
const areaList = (params) => request.get(`/api/Parcel/areaList?platid=${platid}&lang=${lang}`,{params}) // 获取省市区
const getCity = (data) => request.post(`/api/Parcel/getCity?platid=${platid}&lang=${lang}`,data) // 获取包裹城市信息

/* 包裹 */
const transactionType = () => request.get(`/api/index/transactionType?platid=${platid}&lang=${lang}`) // 包裹payment的支付列表
const payment = (data)=>request.post(`api/finance/payment?platid=${platid}&lang=${lang}`,data)//余额不足,立即充值
const getQuote = (data)=>request.post(`api/Parcel/getQuote?platid=${platid}&lang=${lang}`,data)//获取报价
const expressCompany = () => request.get(`api/Parcel/expressCompany?platid=${platid}&lang=${lang}`) // 获取物流公司列表
const getServiceOption = () => request.get(`api/Parcel/getServiceOption?platid=${platid}&lang=${lang}`) // 获取包裹服务列表
const saveParcel = (data) => request.post(`api/Parcel/saveParcel?platid=${platid}&lang=${lang}`,data) // 保存包裹数据
const getParcel = (params) => request.get(`api/Parcel/getParcel?platid=${platid}&lang=${lang}`,{params}) // 获取包裹数据
const getAddress = (params) => request.get(`api/Parcel/getAddress?platid=${platid}&lang=${lang}`,{params}) // 获取地址信息
const getPlaceAddr = (data) => request.post(`api/Parcel/getPlaceAddr?platid=${platid}&lang=${lang}`,data) // 获取第三方地址详细信息

const saveCollectionAddress = (data) => request.post(`api/Parcel/saveCollectionAddress?platid=${platid}&lang=${lang}`,data) // 保存派送地址
const saveMailingAddress = (data) => request.post(`api/Parcel/saveMailingAddress?platid=${platid}&lang=${lang}`,data) // 保存取件地址
const getCollectionAddress = (params) => request.get(`api/Parcel/getCollectionAddress?platid=${platid}&lang=${lang}`,{params}) // 获取派送地址列表
const getMailingAddress = (params) => request.get(`api/Parcel/getMailingAddress?platid=${platid}&lang=${lang}`,{params}) // 获取取件地址列表
const vatStatusList = (params) => request.get(`api/Parcel/vatStatusList?platid=${platid}&lang=${lang}`,{params}) // 获取VAT状态列表
const exportReason = (params) => request.get(`api/Parcel/exportReason?platid=${platid}&lang=${lang}`,{params}) // 获取出口原因
const customsGoods = (params) => request.get(`api/Parcel/customsGoods?platid=${platid}&lang=${lang}`,{params}) // 获取海关物品列表
// const saveTariffCode = (data) => request.post(`api/Parcel/saveTariffCode?platid=${platid}&lang=${lang}`,data) // 编辑税则编号
const getBasket = (params) => request.get(`api/Parcel/getBasket?platid=${platid}&lang=${lang}`,{params}) // 获取购物车信息
const delParcel = (data) => request.post(`api/Parcel/delParcel?platid=${platid}&lang=${lang}`,data) // 删除包裹
const clearShopping = (params) => request.get(`api/Parcel/clearShopping?platid=${platid}&lang=${lang}`,{params}) // 清空购物车
const changeCollectionDate = (data) => request.post(`api/Parcel/changeCollectionDate?platid=${platid}&lang=${lang}`,data) // 更改收款日期
const getBatchQuote = (data) => request.post(`api/Parcel/getBatchQuote?platid=${platid}&lang=${lang}`,data) // 批量下单
const pushYtoOrder = (data) => request.post(`api/Parcel/pushYtoOrder?platid=${platid}&lang=${lang}`,data) // 推送圆通订单
const downInvoice = (params) => request.get(`api/User/downInvoice?platid=${platid}&lang=${lang}`,{params})//下载发票
const getMultiParcels = (params) => request.get(`api/Parcel/getMultiParcels?platid=${platid}&lang=${lang}`,{params})//在支付时获取购物车总价的接口
const searchCode = (params) => request.get(`api/Parcel/searchCode?platid=${platid}&lang=${lang}`,{params})// 获取海关编码的接口
const reOrder = (data) => request.post(`api/User/reOrder?platid=${platid}&lang=${lang}`,data)// 包裹的再次购买
const invoiceList = (data) => request.post(`api/User/invoiceList?platid=${platid}&lang=${lang}`,data)// 发票管理
const getParcelSku = (data) => request.post(`api/Parcel/getParcelSku?platid=${platid}&lang=${lang}`,data)// 获取包裹的sku列表
const modifyPuckup = (data) => request.post(`api/Parcel/modifyPuckup?platid=${platid}&lang=${lang}`,data)// 修改包裹取件时间
const changeExpressArea = (data) => request.post(`api/Parcel/changeExpressArea?platid=${platid}&lang=${lang}`,data)// 改变快递服务分区
const autoAddr = (data) => request.post(`api/Parcel/autoAddr?platid=${platid}&lang=${lang}`,data)//地址自动识别
// 批量下单
const parcelUpload = (data) => request.post(`api/Parcel/parcelUpload?platid=${platid}&lang=${lang}`,data) // 读取快速下单的表格
const updateParcel = (data) => request.post(`api/Parcel/updateParcel?platid=${platid}&lang=${lang}`,data) // 批量下单的包裹修改
const addBasket = (data) => request.post(`api/Parcel/addBasket?platid=${platid}&lang=${lang}`,data) // 批量下单的添加到购物车
const parcelList = (data) => request.post(`api/Parcel/parcelList?platid=${platid}&lang=${lang}`,data) // 批量下单的列表
const deletePD = (data) => request.post(`api/Parcel/deletePD?platid=${platid}&lang=${lang}`,data) // 批量下单的删除包裹
const delMultiParcel = (data) => request.post(`api/Parcel/delMultiParcel?platid=${platid}&lang=${lang}`,data) // 批量删除包裹
const editCollectionAddr = (data) => request.post(`api/Parcel/editCollectionAddr?platid=${platid}&lang=${lang}`,data)//提交派送地址

// 追踪
const searchTrace = (params)=>request.get(`/api/User/searchTrace?platid=${platid}&lang=${lang}`,{params})//京东物流包裹进度查询

/* 航空 */
const getAirplaneList = (data) => request.post(`/api/Parcel/getAirplaneList?platid=${platid}&lang=${lang}`,data) // 获取logo

/* 个人中心 */
const cityCode=()=>request.get(`/api/Parcel/cityCode?platid=${platid}&lang=${lang}`)//获取城市列表
const cityCodeZH=()=>request.get(`/api/Parcel/cityCode?platid=${platid}&lang=${'zh'}`)//获取中文城市列表
const cityCodeEN=()=>request.get(`/api/Parcel/cityCode?platid=${platid}&lang=${'en'}`)//获取英文城市列表
const getPersonalInfo=()=>request.post(`/api/user/getPersonalInfo?platid=${platid}&lang=${lang}`)//获取个人信息
const changePassword=(data)=> request.post(`/api/user/changePassword?platid=${platid}&lang=${lang}`,data)//更改密码
const savePersonal=(data)=> request.post(`/api/user/savePersonal?platid=${platid}&lang=${lang}`,data)//保存个人信息
const savePickupAddress=(data)=>request.post(`/api/user/savePickupAddress?platid=${platid}&lang=${lang}`,data)//保存取件地址
const saveInvoiceAddress=(data)=>request.post(`/api/user/saveInvoiceAddress?platid=${platid}&lang=${lang}`,data)//保存取件地址
const rechargeAccountList=(params)=> request.get(`/api/Finance/rechargeAccountList?platid=${platid}&lang=${lang}`,{params})//充值账户列表
const certificationList=(params)=>request.get(`/api/user/certificationList?platid=${platid}&lang=${lang}`,{params})//获取身份认证列表
const editCertification=(data)=>request.post(`/api/user/editCertification?platid=${platid}&lang=${lang}`,data)//编辑实名认证信息
const delCertification=(data)=>request.post(`/api/user/delCertification?platid=${platid}&lang=${lang}`,data)//删除实名认证信息
const idcardImageCheck=(data)=>request.post(`/api/user/idcardImageCheck?platid=${platid}&lang=${lang}`,data)//获取身份证图片信息
const upload=(data)=> request.post(`/api/common/upload?platid=${platid}&lang=${lang}`,data)//上传图片
const certification=(data)=>request.post(`/api/user/certification?platid=${platid}&lang=${lang}`,data)//提交实名认证信息
const orderList=(params)=>request.get(`/api/user/orderList?platid=${platid}&lang=${lang}`,{params})//获取订单列表
const userOrder=(params)=>request.get(`/api/admin/Order/userOrder?platid=${platid}&lang=${lang}`,{params})//获取运单列表
const deliveryList=(params)=>request.get(`/api/user/deliveryList?platid=${platid}&lang=${lang}`,{params})//获取运单列表
const getInvoiceAddr=(params)=>request.get(`/api/Parcel/getInvoiceAddr?platid=${platid}&lang=${lang}`,{params})//获取发票地址列表
const getPdf=(data)=>request.post(`/api/user/getPdf?platid=${platid}&lang=${lang}`,data)//获取订单的pdf(下载面单,标签)
const deleteCollectionAddress=(data)=>request.post(`/api/Parcel/deleteCollectionAddress?platid=${platid}&lang=${lang}`,data)//删除收货地址
const deleteMailingAddress=(data)=> request.post(`/api/Parcel/deleteMailingAddress?platid=${platid}&lang=${lang}`,data)//删除取件地址
const delInvoice=(data)=>request.post(`/api/User/delInvoice?platid=${platid}&lang=${lang}`,data)//删除发票地址
const judgeExpressPdf=(data)=>request.post(`/api/User/judgeExpressPdf?platid=${platid}&lang=${lang}`,data)//判断快递单号是否存在
const judgeInvoice=(data)=>request.post(`/api/User/judgeInvoice?platid=${platid}&lang=${lang}`,data)//判断是否能下载发票
const parcelMoneyInfo=(data)=>request.post(`/api/User/parcelMoneyInfo?platid=${platid}&lang=${lang}`,data)//判断是否能下载发票
const addIdcard=(data)=>request.post(`/api/User/addIdcard?platid=${platid}&lang=${lang}`,data)//修改包裹姓名和身份证号
const payExtraMoney=(data)=>request.post(`/api/Parcel/payExtraMoney?platid=${platid}&lang=${lang}`,data)//订单差价支付
const getMdInfo=(params)=> request.get(`/api/User/getMdInfo?platid=${platid}&lang=${lang}`,{params})//获取面单信息

export default{
    /* 平台id */ 
    platid,
    /* 登陆注册忘记密码 */
    emailLogin,
    emailRegister,
    sendEmailCode,
    mobileLogin,
    mobileRegister,
    sendMobileCode,
    logout,
    checkEmail,
    emailPwdLogin,
    forgotPwd,
    /* 客服 */ 
    openapiSign,
    openapiAibot,
    /*  首页  */ 
    logoImg,
    switchLanguage,
    getUniqid,
    /* 公共 */
    areaList,
    getCity,
    /* 包裹 */
    transactionType,
    payment,
    getQuote,
    expressCompany,
    getServiceOption,
    saveParcel,
    getParcel,
    getAddress,
    getPlaceAddr,
    saveCollectionAddress,
    saveMailingAddress,
    getCollectionAddress,
    getMailingAddress,
    vatStatusList,
    exportReason,
    customsGoods,
    getBatchQuote,
    getMultiParcels,
    getParcelSku,
    modifyPuckup,
    changeExpressArea,
    autoAddr,
    // saveTariffCode,
    getBasket,
    delParcel,
    clearShopping,
    changeCollectionDate,
    pushYtoOrder,
    downInvoice,
    parcelUpload,
    updateParcel,
    addBasket,
    parcelList,
    deletePD,
    searchCode,
    delMultiParcel,
    reOrder,
    invoiceList,
    editCollectionAddr,
    // 追踪
    searchTrace,
    /* 航空 */ 
    getAirplaneList,
    /* 个人中心 */
    cityCode,
    cityCodeZH,
    cityCodeEN,
    getPersonalInfo,
    savePickupAddress,
    changePassword,
    savePersonal,
    saveInvoiceAddress,
    rechargeAccountList,
    certificationList,
    editCertification,
    delCertification,
    upload,
    idcardImageCheck,
    certification,
    orderList,
    userOrder,
    deliveryList,
    getPdf,
    deleteCollectionAddress,
    deleteMailingAddress,
    getInvoiceAddr,
    delInvoice,
    judgeExpressPdf,
    judgeInvoice,
    parcelMoneyInfo,
    addIdcard,
    payExtraMoney,
    getMdInfo,
}

