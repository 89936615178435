import { getCookie,setCookie } from '@/utils/cookie'
let admin = {
	/* eslint-disable */
	namespaced: true,
	state: {
		token:getCookie('token')||'',
		CSToken:getCookie('CSToken')||'',
		auth_token:getCookie('auth_token')||'',
		tokenStatus:false,
		getUniqid:getCookie('getUniqid')||'',
		dialog:false,
		userInfo:null,
		menuCollapse:false,
		realNameInfo:null,
		basketNum:0,
		userLogo:null,
		cityCode:[],
		cookieStorage:JSON.parse(localStorage.getItem('Accept'))||false,
	},
	mutations: {
		//同意存储cookie
		storageCookie(state,data){
			localStorage.setItem('Accept',JSON.stringify(data))
			state.cookieStorage = data
		},
		//页面关闭清除保存的数据
		removeLocalData(){
			localStorage.removeItem('token')
		},
		//存入cityCode
		storageCityCode(state,val){
			state.cityCode = val
		},
		//登录,存入token
		login(state,[token,status]) {
			setCookie('token',token,status)
			state.token = token;
		},
		//客服登录,存入token
		CSLogin(state,data) {
			setCookie('CSToken',data.token)
			state.CSToken = data.token;
			setCookie('auth_token',data.auth_token)
			state.auth_token = data.auth_token;
		},
		//退出登录
		logOut(state) {
      		state.token=''
			document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC"
			state.CSToken=''
			document.cookie = "CSToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC"
			state.auth_token=''
			document.cookie = "auth_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC"
		},
		//若没有登陆则存入唯一id值
		storageUniqid(state,index){
			setCookie('getUniqid',index)
			state.getUniqid = index
		},
		//登录的模态框
		dialogShow(state,index){
			state.dialog=index
		},
		//存入个人信息
		storageUserInfo(state,info){
			console.log(info);
			state.userInfo=info
		},
		//改变个人中心的订单视图
		changeMeunCollapse(state,data){
			state.menuCollapse=data
		},
		//存入图片信息
		storageUserLogo(state,data){
			state.userLogo=data
		},
		//修改个人信息
		editUserinfo(state,data){
			try {
				state.userInfo.first_name=data[0]
				state.userInfo.last_name=data[1]
			} catch (error) {
				return error
			}
		},
		//修改个人手机号
		editUserPhone(state,data){
			try {
				state.userInfo.mobile=data
			} catch (error) {
				return error
			}
		},
		//充值过后更新金额
		updatePrice(state,data){
			try {
				state.userInfo.money=(Number(state.userInfo.money)+data).toFixed(2)
			} catch (error) {
				return error
			}
		},
		//将实名认证信息存入
		storageRealNameInfo(state,data){
			state.realNameInfo = data
		},
		//存入购物车的数量
		storageBasketNum(state,data){
			// console.log(data);
			state.basketNum=data
		},
	},
};
export default admin;
