let track = {
	/* eslint-disable */
	namespaced: true,
	state: {
		searchContent:sessionStorage.getItem('searchContent')||''
	},
	mutations: {
		storageSearchContent(state,data){
			state.searchContent=data
		},
	},
};
export default track;