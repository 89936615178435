import request from "./CSRequest";
export const platid='MDAwMDAwMDAwMLOcdnU';//分平台的平台id
let lang=localStorage.getItem('languageSet')||'zh'

const userLogin=(data)=>request.post(`/addons/fastim/api.user/chatLogin`,data)//客服登录
const initialize=(params)=> request.get(`/addons/fastim/index/initialize`,{params:{referrer:'uni-app',...params}})//客服初始化
const uploadImg=(data)=>request.post(`/addons/fastim/index/upload`,data)//客服上传图片
export default{
    /* 平台id */ 
    platid,
    // 客服
    userLogin,
    initialize,
    uploadImg,
}

