//plan模块的语言
export const search={
    ZH:{
        search:'获取报价',
        multipleParcel:'批量下单',
        tip:'，最大为:10kg--60×50×40 cm',
    },
    EN:{
        search:'Search',
        multipleParcel:'Send Multiple Parcel',
        tip:',Max:10kg--60×50×40 cm',
    }
}
export const plan={
    ZH:{
        parcel:'包裹',
        cargo:'货运',
        flights:'机票',
        hotel:'酒店',
        exchangeRate:'汇率',
        track:'追踪',
    },
    EN:{
        parcel:'Parcel',
        cargo:'Cargo',
        flights:'Flights',
        hotel:'Hotel',
        exchangeRate:'Exchange Rate',
        track:'Track',
    }
}
export const home={
    ZH:{
        headers:'感受这里的不同',
        people:'连接人员',
        Living:'创新生活',
        Routes:'热门路线上的优惠',
        London:'伦敦',
        Dublin:'都柏林',
        Manchester:'曼彻斯特',
        Bangkok:'曼谷',
        From:'开始',
        date:'12月6日星期二--12月13日星期二',
        avgPrice:'每人单程/回程平均价格，包括税费',
        Attractions:'热门景点',
        more:'更多',
        Properties:'特色房产',
        now:'现在开始',
        search:'包裹、货运、机票',
        Popular:'热门目的地',
        Bussiness:'企业客户',
        Private:'私人客户',
    },
    EN:{
        headers:'Feel The Difference Here',
        people:'Connecting people',
        Living:'Innovation Living',
        Routes:'Deals on Popular Routes',
        London:'London',
        Dublin:'Dublin',
        Manchester:'Manchester',
        Bangkok:'Bangkok',
        From:'From',
        date:'Tue,6 Dec-Tue, 13 Dec',
        avgPrice:'Average one-way/return price per person,taxes and fees incl',
        Attractions:'Popular Attractions',
        more:'More',
        Properties:'Featured Properties',
        now:"Go Now",
        search:'Parcel、Cargo、Flight',
        Popular:'Popular Destinations',
        Bussiness:'Enterprise Customers',
        Private:'Private Customer',
    },
}
//下方的推荐
export const introduce = {
    ZH:{
        parcelTitle:'包裹',
        default:'包裹下单',
        fast:'快速下单',
        batch:'批量下单',
        cargoTitle:'货运',
        flightTitle:'机票',
        businessSector:'业务板块',
    },
    EN:{
        parcelTitle:'Parcel',
        default:'Place an order',
        fast:'Quick order',
        batch:'Batch order placement',
        cargoTitle:'Cargo',
        flightTitle:'Plane ticket',
        businessSector:'Business sector',
    },
}
export const cookie = {
    ZH:{
        title:'管理cookie首选项',
        content:`点击“接受”，即表示您同意使用分析cookie（用于了解网站使用情况，并用于改进您的网站和服务）和跟踪cookie（均来自htt.uk.com和其他值得信赖的合作伙伴），这些cookie将决定只在社交媒体上向您展示哪种产品。点击这里，您可以离开我们的网站，测量访问我们网站的受众，并使您能够直接点赞或分享内容。接受拒绝并管理您的同意，并查找有关我们使用的cookie的更多信息。`,
        Accept:'接受',
    },
    EN:{
        title:'Manage cookie preferences',
        content:`By clicking "Accept" you agree with the use of analytical cookies (which are used to gain insight on webite usage and which are used to improveour site and services) and tracking cookies (both from htt.uk.com and other trusted partners) that heldecide which product to show you only on social media. By clicking here, youand off our site, measure the audience visiting our websites, and enable you to like or share things direcearc Accept Decline an manage your consent and find more information about the cookies we use.`,
        Accept:'Accept',
    },
}