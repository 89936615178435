const parcel=[
    {
        path:'quotationDetails',
        name:'quotationDetails',
        component:()=>import('@/views/parcel/components/quotationDetails.vue'),
        meta:{
            title:'',
            parcelSetup:true,
            parcelShop:true,
        }
    },
    {
        path:'parcelDetails',
        name:'parcelDetails',
        component:()=>import('@/views/parcel/components/parcelDetails.vue'),
        meta:{
            // title:'包裹详情',
            parcelSetup:true,
            parcelShop:true,
            parcelLogin:true,
        }
    },
    {
        path:'addressDetails',
        name:'addressDetails',
        component:()=>import('@/views/parcel/components/addressDetails.vue'),
        meta:{
            // title:'地址详情',
            parcelSetup:true,
            parcelShop:true,
            parcelLogin:true,
        }
    },
    {
        path:'customs',
        name:'customs',
        component:()=>import('@/views/parcel/components/customsView.vue'),
        meta:{
            // title:'海关信息',
            parcelSetup:true,
            parcelShop:true,
            parcelLogin:true,
        }
    },
    {
      path:'parcelBasket',
      name:'parcelBasket',
      component:()=>import('@/views/parcel/components/parcelBasket.vue'),
      meta:{
          // title:'购物车',
          parcelSetup:true,
          parcelShop:true,
          parcelLogin:true,
          parcelBasket:true,
      }
    },
    {
      path:'parcelPayment',
      name:'parcelPayment',
      component:()=>import('@/views/parcel/components/parcelPaymentView.vue'),
      meta:{
          // title:'支付',
          parcelSetup:true,
          parcelShop:true,
      }
    },
    {
        path:'/multipleParcel',
        name:'multipleParcel',
        component:()=>import('@/views/parcel/components/parcelMultiple.vue'),
        meta:{
            top:true,
            // title:'多选包裹',
            keepAlive:true,
            parcelShop:true,
        }
      },
    {    
        path:'/quickSend',
        name:'quickSend',
        component:()=>import('@/views/parcel/quickSend.vue'),
        meta:{
          // title:'快速发送',
          isLogin:true,
            parcelShop:true,
        }
      },
      {
        path:'/quickQuote',
        name:'quickQuote',
        component:()=>import('@/views/parcel/quickQuote.vue'),
        meta:{
          // keepAlive:true,
          // title:'有哪些包裹',
            parcelShop:true,
        }
      },
    {
        path:'/uploadCsv',
        name:'uploadCsv',
        component:()=>import('@/views/parcel/components/uploadCsv.vue'),
        meta:{
            // title:'上传文件',
            isLogin:true,
            parcelShop:true,
        }
    },
    {
        path:'/linkAccount',
        name:'linkAccount',
        component:()=>import('@/views/parcel/components/linkAccount.vue'),
        meta:{
            // title:'绑定账号',
            parcelShop:true,
        }
      },
      {
        path:'/quickOrder',
        name:'quickOrder',
        component:()=>import('@/views/parcel/quickOrder.vue'),
        meta:{
            // title:'快速下单',
            isLogin:true,
            parcelShop:true,
        }
      },
      {
        path:'/paySuccess',
        name:'paySuccess',
        component:()=>import('@/views/parcel/components/paySuccess.vue'),
        meta:{
            // title:'支付成功',
            parcelShop:true,
        }
    },
    // {
    //   path:'/parcelInfoMore',
    //   name:'parcelInfoMore',
    //   component:()=>import('@/views/parcel/components/confirmParcelInfo.vue'),
    //   meta:{
    //       // title:'完善包裹信息',
    //         parcelShop:true,
    //   }
    // },
    {
      path:'/advicePacking',
      name:'advicePacking',
      component:()=>import('@/views/parcel/components/advicePacking.vue'),
      meta:{
          // title:'盒子大小',
            parcelShop:true,
      }
    },
    {
      path:'/downLoadInvoice',
      name:'downLoadInvoice',
      component:()=>import('@/views/parcel/components/downloadInvoice.vue'),
      meta:{
          // title:'下载发票',
            parcelShop:true,
      }
    },
    {
      path:'/readyLabel',
      name:'readyLabel',
      component:()=>import('@/views/parcel/components/readyLabel.vue'),
      meta:{
          // title:'准备标签',
            parcelShop:true,
      }
    },
    {
      path:'/sfDetails',
      component:()=>import('@/views/parcel/components/parcelView/sfDetails.vue'),
      meta:{
        top:true
      }
    }
]
export default parcel