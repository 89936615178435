let service = {
	/* eslint-disable */
	namespaced: true,
	state: {
		openapiSign:localStorage.getItem('openapiSign')||'',
	},
	mutations: {
        //存储充值账户的信息
		setOpenapiSign(state,val){
            // console.log(val);
            localStorage.setItem('openapiSign',JSON.stringify(val))
            state.openapiSign=val
        },
	}
};
export default service;
