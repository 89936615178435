import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/language'
import api from '@/api/index'
import CSApi from '@/api/CSIndex'
import ElementUI from 'element-ui'
import resetMessage from './utils/message'

Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/font/iconfont.css'
import '@/assets/css/style.css'

Vue.config.productionTip = false

Vue.prototype.$api=api
Vue.prototype.$CSApi=CSApi
Vue.prototype.$message = resetMessage;


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

console.log(new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app'));