//头部导航条
export const navbar={
    ZH: {  //中文翻译
        parcel:'包裹',
        cargo:'货运',
        flights:'机票',
        // hotel:'酒店',
        // taxi:'专车',
        // shopping:'商城',
        // hb:'航班动态',
        // hl:'汇率',
        track:'追踪'
    },
    EN: {  //英文翻译
        parcel:'Parcel',
        cargo: "Cargo",
        flights:'Flights',
        // hotel:'Hotel',
        // taxi:'Special train',
        // shopping:'shopping',
        // hb:'Flight Information',
        // hl:'Exchange Rate',
        track:'Track'
    }
}
//导航条右侧
export const navbarRight = {
    ZH:{
        BUSINESS:'内网客户'
    },
    EN:{
        BUSINESS:'BUSINESS',
    },
}
//个人中心
export const person={
        ZH: {
            myOrder: "我的订单",
            myPurse:'财务管理', 
            quickSend:'快速下单',
            myInfo:'账户管理',
            logOut:'退出登录',
        },
        EN: { 
            myOrder: "My Bookings",
            myPurse:'Prepay Balance',
            quickSend:'Quick Send',
            myInfo:'Userinfo',
            logOut:'Sign Out'
        }
}
export const member={
    ZH:{
        member:'会员'
    },
    EN:{
        member:"Member"
    }
}
//底部的语言包
export const footer={
    ZH:{
        Contact:'联系我们',
        Customer:'Whatsapp我们',
        Service:'邮箱',
        Website:'联系我们',
        About:'关于',
        aboutUs:'关于我们',
        news:'最新动态',
        TermsAndConditions:'条款和条件',
        privacyAndCookies:'隐私和Cookie',
        Other:'其他服务',
        Investor:'投资者关系',
        Hotel:'列出我的酒店',
        Become:'成为供应商',
        Payment:'付款方式',
        Airlines:'航空公司合作伙伴',
        Our:'物流合作伙伴',
        Copyright:'版权所有@2023 HTT 国际有限公司。',
        Site:'网站运营商：HTT 国际有限公司',
    },
    EN:{
        Contact:'Contact Us',
        Customer:'Whatsapp Us',
        Service:'Email us',
        Website:'Contact us',
        About:'About',
        aboutUs:'About Us',
        news:'News ',
        TermsAndConditions:'Terms & Conditions',
        privacyAndCookies:'Privacy and Cookies',
        Other:'Other Services',
        Investor:'Become a Network Partner',
        Hotel:'Careers',
        Become:'Refund Policy',
        Payment:'Payment Methods',
        Airlines:'Airlines Partners',
        Our:'Logistics partners',
        Copyright:'版权所有@2023 HTT 国际有限公司。',
        Site:'网站运营商：HTT 国际有限公司',
    },
}