import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import user from './modules/user'
import parcel from './modules/parcel'
import cargo from './modules/cargo'
import flight from './modules/flight'
import track from './modules/track'
import other from './modules/other'
import footer from './modules/footer'
import store from '@/store'
// import flights from './modules/flight'
import { Loading } from 'element-ui'

const routes = [
  {
    path: '/test',
    name: 'test',
    component:()=>import('@/components/testView.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component:()=>import('@/views/home/homeView.vue'),
    meta:{
      navbar:true,
      keepAlive:true,
      // title:'首页',
    }
  },
  {
    path:'/parcel',
    name:'parcel',
    component:()=>import('@/views/parcel/parcelView.vue'),
    children:parcel,
    meta:{
      // keepAlive:true,
      // title:'包裹',
      parcelSetup:true,
    }
  },
  {
    path:'/cargo',
    name:'cargo',
    component:()=>import('@/views/cargo/cargoView.vue'),
    children:cargo,
    meta:{
      // title:'空运',
      secondaryPage:true,
      // keepAlive:true,
    }
  },
  {
    path: '/flights',
    name: 'flights',
    component:()=>import('@/views/flight/flightView.vue'),
    children:flight,
    meta:{
      // title:'机票'
    }
  },
  {
    path: '/hotel',
    name: 'hotel',
    component:()=>import('@/views/hotel/hotelView.vue'),
    children:flight,
    meta:{
      // title:'酒店'
    }
  },
  {
    path:'/track',
    name:'track',
    component:()=>import('@/views/track/index.vue'),
    children:track,
    meta:{
      // title:'追踪',
    }
  },
  {
    path:'/user',
    name:'user',
    component:()=>import('@/views/user/userView.vue'),
    children:user,
    meta:{
      // title:'用户',
    }
  },
  {
    path:'/basket',
    name:'basket',
    component:()=>import('@/components/basketView.vue'),
    meta:{
        // title:'购物车',
        parcelSetup:true,
        parcelShop:true,
    }
},
{
    path:'/parcelPayment',
    name:'parcelPayment',
    component:()=>import('@/components/paymentView.vue'),
    meta:{
        // title:'开始支付',
        parcelSetup:true,
        parcelShop:true,
    }
},
  {
    path:'/orderBlockDetails',
    name:'orderBlockDetails',
    component:()=>import('@/components/orderBlockDetails.vue'),
    meta:{
      // title:'订单详情页'
    }
  },
  {
      path:'/payAddition',
      name:'payAddition',
      component:()=>import('@/views/user/componends/payAddition.vue'),
      meta:{
          top:true,
          // title:'支付额外附加费用',
          isLogin:true,
      }
  },
  {
    path:'/service',
    name:'service',
    component:()=>import('@/components/serviceView.vue'),
    meta:{
      // title:'客服',
    }
  },
  // {
  //   path:'/login',
  //   name:'login',
  //   component:()=>import('@/components/login.vue'),
  //   meta:{
  //     // title:'登录',
  //   }
  // },
  {
    path:'/other',
    name:'other',
    component:()=>import('@/components/other.vue'),
    children:other,
    meta:{
        // title:'其他页面'
    }
},
{
  path:'/footer',
  name:'footer',
  component:()=>import('@/views/footer/footerContent.vue'),
  children:footer,
  meta:{
      // title:'底部的页面'   
  }
},
  {
    path:'/develop',
    name:'develop',
    component:()=>import('@/components/developView.vue'),
    meta:{
      // title:'空白页'
    }
  },
  {
    path:'/error',
    name:'error',
    component:()=>import('@/views/errorView.vue'),
    meta:{
      // title:'错误'
    }
  },
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '*',
    redirect:'/develop'
  },
]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
const router = new VueRouter({
  mode:'hash',
  scrollBehavior (to, from, savedPosition){
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
  },
  routes
})

//路由拦截
let loadingInstance1;
router.beforeEach((to,from,next)=>{
  // document.title=to.meta.title
  document.title='HTT'
  loadingInstance1 = Loading.service({
    fullscreen:true,
    background:'rgba(0, 0, 0, 0.8)',
  });
  if(to.meta.isLogin){
    // console.log(store.state.admin.token);
    if(store.state.admin.token){
      next()
    }else{
      next({
        path:'/home',
        query:{
          redirect:to.fullPath
        }
      })
    }
  }else{
    next()
  }
})
router.afterEach(()=>{
  loadingInstance1.close()
})
export default router
