import axios from 'axios'
import Message from '@/utils/message'
import store from '@/store'
let count = 0;
const request = axios.create({
  baseURL:process.env.VUE_APP_CSURL,
})
// 请求拦截
request.interceptors.request.use(
  async config => {
    // config.headers.token=store.state.admin.token
    return config
  },
  error => {
    return Promise.reject(error)
  })

// 响应拦截
request.interceptors.response.use(
  async response => {
    return response
  },
  error => {
    if(!error.response){
      Message.error(error.message)
    }
    return Promise.reject(error.response.status)
  })

export default request
