const user=[
    //我的订单
    {
        path:'orderAll',
        name:'myOrder',
        component:()=>import('@/views/user/componends/orderAll.vue'),
        meta:{
            top:true,
            keepAlive:true,
            // title:'全部订单',
            isLogin:true,
        }
    },
    {
        path:'orderParcel',
        name:'orderParcel',
        component:()=>import('@/views/user/componends/orderParcel.vue'),
        meta:{
            top:true,
            keepAlive:true,
            // title:'包裹订单',
            isLogin:true,
        }
    },
    {
        path:'orderAir',
        name:'orderAir',
        component:()=>import('@/views/user/componends/orderAir.vue'),
        meta:{
            top:true,
            keepAlive:true,
            // title:'空运订单',
            isLogin:true,
        }
    },
    {
        path:'orderFlights',
        name:'orderFlights',
        component:()=>import('@/views/user/componends/orderFlights.vue'),
        meta:{
            top:true,
            keepAlive:true,
            // title:'机票订单',
            isLogin:true,
        }
    },
    //财务管理
    {
        path:'payment',
        name:'myPurse',
        component:()=>import('@/views/user/componends/payNow.vue'),
        meta:{
            top:true,
            // title:'充值账户',
            isLogin:true,
        }
    },
    {
        path:'payAccount',
        name:'payAccount',
        component:()=>import('@/views/user/componends/payAccount.vue'),
        meta:{
            top:true,
            // title:'立即充值',
            isLogin:true,
        }
    },
    {
        path:'payRecord',
        name:'payRecord',
        component:()=>import('@/views/user/componends/payRecord.vue'),
        meta:{
            top:true,
            // title:'申请记录',
            isLogin:true,
        }
    },
    {
        path:'payInvoice',
        name:'payInvoice',
        component:()=>import('@/views/user/componends/payInvoice.vue'),
        meta:{
            top:true,
            // title:'发票管理',
            isLogin:true,
        }
    },
    // 账户管理
    {
        path:'accountSet',
        name:'myInfo',
        component:()=>import('@/views/user/componends/accountSet.vue'),
        meta:{
            top:true,
            // title:'账户设置',
            isLogin:true,
        }
    },
    {
        path:'safetySet',
        name:'safetySet',
        component:()=>import('@/views/user/componends/accountSafe.vue'),
        meta:{
            top:true,
            // title:'安全设置',
            isLogin:true,
        }
    },
    {
        path:'accountReal',
        name:'accountReal',
        component:()=>import('@/views/user/componends/accountReal.vue'),
        meta:{
            top:true,
            // title:'实名认证',
            isLogin:true,
        }
    },
    {
        path:'accountRealList',
        name:'accountRealList',
        component:()=>import('@/views/user/componends/accountRealList.vue'),
        meta:{
            top:true,
            // title:'认证列表',
            isLogin:true,
        }
    },
    {
        path:'accountLink',
        name:'accountLink',
        component:()=>import('@/views/user/componends/accountLink.vue'),
        meta:{
            top:true,
            // title:'关联账户',
            isLogin:true,
        }
    },
    {
        path:'addressSet',
        name:'addressSet',
        component:()=>import('@/views/user/componends/accountAddress.vue'),
        meta:{
            top:true,
            // title:'地址管理',
            isLogin:true,
        }
    },
    {
        path:'packingList',
        name:'packingList',
        component:()=>import('@/views/user/componends/accountPacking.vue'),
        meta:{
            top:true,
            // title:'装箱单管理',
            isLogin:true,
        }
    },
]
export default user